import {
  ChatIcon,
  FolderIcon,
  HomeIcon,
  PuzzleIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import { ChartBarIcon, TagIcon } from "@heroicons/react/solid";

export const navigation = [
  { name: "Dashboard", href: "/admin", icon: HomeIcon, current: true },
  {
    name: "Clients",
    href: "/admin/addclient",
    icon: UsersIcon,
    current: false,
  },
  {
    name: "Products",
    href: "/admin/products",
    icon: FolderIcon,
    current: false,
  },
  { name: "SMS", href: "/admin/sms", icon: ChatIcon, current: false },
  {
    name: "Bulk SMS",
    href: "/admin/bulksms",
    icon: ChartBarIcon,
    current: false,
  },
  {
    name: "Pure",
    href: "/admin/pure-client",
    icon: TagIcon,
    current: false,
  },
  {
    name: "King",
    href: "/admin/king-client",
    icon: TagIcon,
    current: false,
  },
  {
    name: "Magnum",
    href: "/admin/magnum-client",
    icon: TagIcon,
    current: false,
  },
  {
    name: "4k",
    href: "/admin/4k-client",
    icon: TagIcon,
    current: false,
  },
  {
    name: "Template",
    href: "/admin/template",
    icon: PuzzleIcon,
    current: false,
  },
  // { name: "Teams", href: "/teams", icon: CalendarIcon, current: false },
  // { name: "Plans", href: "plans", icon: InboxIcon, current: false },
  // { name: "Reports", href: "reports", icon: ChartBarIcon, current: false },
];
