import img1 from "../assets/firetv/1.png";
import img2 from "../assets/firetv/2.png";
import img3 from "../assets/firetv/3.png";
import img4 from "../assets/firetv/4.png";
import img5 from "../assets/firetv/5.png";
import img6 from "../assets/firetv/6.png";
import img7 from "../assets/firetv/7.png";
import img8 from "../assets/firetv/8.png";
// import img9 from "../assets/firetv/9.png";
import img10 from "../assets/firetv/10.png";
import img11 from "../assets/firetv/11.png";

export const images = {
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  //   img9,
  img10,
  img11,
};
