import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "../utils/Loader";
import { useParams, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const AcceptPage = () => {
  let history = useHistory();
  let { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const getInfoUser = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/client/${id}`,
      withCredentials: true,
    })
      .then((res) => {
        setUser(res.data);
        console.log("res.data", res.data);
      })
      .catch((err) => console.log("err", err));
  };

  useEffect(() => {
    getInfoUser();
  }, []);

  const acceptCondition = async () => {
    setLoading(true);
    await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/api/client/accept/${id}`,
      withCredentials: false,
      data: {
        accepted: true,
      },
    })
      .then((res) => {
        console.log("donnée envoyé : ", res);
        toast.success("Succès !", {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          history.replace("/");
          setLoading(false);
        }, 2500);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div className="min-h-screen ">
      <h1 className="text-xl font-extralight text-center mt-5">
        Welcome {user?.name}
      </h1>
      <div className=" lg:p-10 p-5">
        <div className="text-2xl text-center">
          Merci de lire et d'accepter les conditions
        </div>
        <div className="text-2xl text-center font-light italic">
          Please read and accept the conditions
        </div>
        <div className="text-md text-gray-300 mt-5  ">
          <div className="text-justify">
            le service iptv est un service non légal aui exsite depuis plus de
            10 ans à travers le monde du fait que le service présente des
            services de VOD habituellement paiement et de haute qualitée En tant
            que simple revendeur, nous ne pouvons rembourser aucun paiement
            comme nous l'exige nos fournisseurs car nous ne possédons aucun
            serveur et nous ne maitrisons aucun aspect technique ! Toutes
            réclamations devra se faire dans le respect en consiérant que le
            service que nous proposons vise a réduire ou a vous permettre
            d'accéder a programmes payant ou non disponbles dans votre pays. Des
            tests de 24h sont mis a dispositions sur certains serveur afin que
            le client puisse tester le service.
          </div>
          <div className="text-justify mt-5 italic font-light ">
            the iptv service is a non-legal service that has been in existence
            for more than 10 years throughout the world, as the service presents
            VOD services that are usually paid for and of high quality. As a
            simple reseller, we cannot refund any payments as required by our
            suppliers, as we do not own any servers and we do not control any
            technical aspects! All claims must be made with respect, bearing in
            mind that the service we offer is designed to reduce or enable you
            to access programs that are not available in your country. 24-hour
            tests are available on certain servers so that customers can test
            the service.
          </div>

          {user?.name && (
            <h1 className=" font-extralight text-center mt-5">
              j'accepte en tant que :{" "}
              <span className="font-bold underline"> {user?.name}</span>
            </h1>
          )}

          {loading ? (
            <div className="mt-10">
              <Loader />
            </div>
          ) : (
            <div className="flex">
              <button
                className="mx-auto px-7 py-2 rounded-lg mt-10 bg-red-500 hover:bg-red-400  shadow-lg  shadow-red-500/50 "
                onClick={acceptCondition}
              >
                J'accepte les conditions
              </button>
            </div>
          )}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AcceptPage;
