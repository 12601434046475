import React from "react";

const ButtonPanel = ({ setSidebarOpen, link, name }) => {
  return (
    <div>
      <button
        className="flex-shrink-0 group block"
        onClick={() => {
          window.open(link);
          window.location.replace("/admin/addclient");
          setSidebarOpen(false);
        }}
      >
        <div className="flex items-center">
          <div className="ml-3">
            <p className="text-md font-medium text-white group-hover:text-gray-300 ">
              {name}
            </p>
          </div>
        </div>
      </button>
    </div>
  );
};

export default ButtonPanel;
