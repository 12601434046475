import { BrowserRouter as Router } from "react-router-dom";
import AdminRoute from "./routes/AdminRoute";
import ClientRoute from "./routes/ClientRoute";
import { inject } from "@vercel/analytics";

import { useEffect } from "react";
import ReactGA from "react-ga";
const TRACKING_ID = "G-GK0Z1B8NZX";
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <Router>
        {inject()}
        {localStorage.getItem("id") ? <AdminRoute /> : <ClientRoute />}
      </Router>
    </div>
  );
}

export default App;
