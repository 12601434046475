import { combineReducers, configureStore } from "@reduxjs/toolkit";

import tokenReducer from "./token";

const rootReducer = combineReducers({
  token: tokenReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export default store;
