export const PANELS = [
  {
    id: 0,
    title: "Panel Standard",
    description: "Panel avec serveur Stable - toutes les chaînes avec VoD",
    price: 75,
    current: true,
  },
  {
    id: 1,
    title: "Panel Medium",
    description: "Panel avec serveur Stable - Chaînes en 4k et plus de VoD",
    price: 110,
    current: false,
  },
  {
    id: 2,
    title: "Panel 4K",
    description: "Panel avec serveur Stable - Chaînes en 4k et plus de VoD",
    price: 150,
    current: false,
  },
  {
    id: 3,
    title: "Panel Premium",
    description:
      "Serveur Très Stable - Chaînes en 4k et plus de VoD de haute Qualité sans coupure Garantie ! ",
    price: 220,
    current: false,
  },
];
