/* eslint-disable jsx-a11y/no-redundant-roles */
import { useState } from "react";
import pureClient from "../../data/pure.json";

import TablePanelPure from "./TablePanelPure";
import axios from "axios";
import { formatPhone } from "../../utils/formatPhone";
import { ToastContainer, toast } from "react-toastify";

export default function PureClients() {
  const [query, setQuery] = useState("");
  const [messagetoSend, setMessagetoSend] = useState();
  const [currentUser, setCurrentUser] = useState({});
  const [messageError, setMessageError] = useState("");
  const [phone, setPhone] = useState();
  const [open, setOpen] = useState(false);

  const [defaultMessage, setDefaultMessage] = useState();

  const parser = new DOMParser();

  const format = (test) => {
    const htmlDoc = parser.parseFromString(test, "text/html");
    const enabledText = htmlDoc.body.textContent;
    return enabledText;
  };

  const sendMessage = async () => {
    if (phone.length === 10 || phone.length === 11) {
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/message/sendsms`,
        data: {
          body: messagetoSend,
          // from: "+12184384469",
          from: process.env.REACT_APP_PHONE,
          to: `+1${formatPhone(phone)}`,
        },
      })
        .then((res) => {
          // history.push("/admin/addclient");
          toast.success("SMS sent Successfull", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setOpen(false);
          console.log(res.status);
        })
        .catch((err) => console.log("Errorr", err));
    } else {
      setMessageError("Ceci n'est pas un Numéro de téléphone valide");
      setTimeout(() => {
        setMessageError(null);
      }, 3000);
    }
  };

  return (
    <div className="bg-gray-800 mb-5 min-h-screen">
      <div className="bg-gradient-to-b from-cyan-600 to-cyan-100 p-2">
        <div className="max-w-7xl mx-auto pt-2 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:flex-col sm:align-center">
            <h1 className="text-5xl font-extrabold text-gray-900 text-center">
              Clients Pure
            </h1>
            <button
              className=" flex text-center text-white mx-auto rounded-xl bg-teal-500 m-2 px-8 py-1 hover:bg-teal-600  shadow-lg shadow-teal-500/50 "
              onClick={() => {
                window.open("https://panel.theking365tv.info/lines");
                // window.location.replace("/admin/addclient");
              }}
            >
              Accès au Panel Pure
            </button>
            <p className="mt-1 text-md text-gray-500 text-center"></p>
          </div>
        </div>
        <p className="text-xl text-center"> {pureClient?.data.length} Codes</p>
      </div>

      <div className=" px-12 mt-5 ">
        <input
          className="border-5 border-orange-500 w-4/5 lg:w-1/2 mx-auto flex h-10 rounded-lg shadow-md mb-2"
          type="text"
          placeholder="Search By Name..."
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>

      <div className=" mt-5">
        <div className=" sm:block">
          <div className="max-w-6xl mx-auto px-4 sm:px-2 lg:px-8">
            <div className="flex flex-col mt-2">
              <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 bg-gray-600 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                        -
                      </th>
                      <th className="px-6 py-3 bg-gray-600 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                        Name
                      </th>
                      <th className="px-6 py-3 bg-gray-600 text-center text-xs font-medium text-gray-100 uppercase tracking-wider">
                        Password
                      </th>

                      <th className="px-6 py-3 bg-gray-600 text-center text-xs font-medium text-gray-100 uppercase tracking-wider">
                        Exp. Date
                      </th>
                      <th className="px-6 py-3 bg-gray-600 text-center text-xs font-medium text-gray-100 uppercase tracking-wider">
                        Notes
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-gray-500 divide-y divide-gray-700">
                    <TablePanelPure
                      clients={pureClient}
                      format={format}
                      query={query}
                      sendMessage={sendMessage}
                      setCurrentUser={setCurrentUser}
                      currentUser={currentUser}
                      messagetoSend={messagetoSend}
                      defaultMessage={defaultMessage}
                      setDefaultMessage={setDefaultMessage}
                      setMessagetoSend={setMessagetoSend}
                      messageError={messageError}
                      phone={phone}
                      setPhone={setPhone}
                      open={open}
                      setOpen={setOpen}
                    />
                  </tbody>
                </table>
                {/* Pagination */}
                {/* <nav
                    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                    aria-label="Pagination"
                  >
                    <div className="hidden sm:block">
                      <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">1</span> to
                        <span className="font-medium">10</span> of
                        <span className="font-medium">20</span> results
                      </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                      <a
                        href="#"
                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Previous
                      </a>
                      <a
                        href="#"
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Next
                      </a>
                    </div>
                  </nav> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
