import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/fr";
import CopyToClipboard from "react-copy-to-clipboard";
import { ChatIcon, ClipboardCopyIcon } from "@heroicons/react/solid";

import { ToastContainer, toast } from "react-toastify";
import ModalSentPanel from "../../components/admin/ModalSentPanel";
import { compareTimestamp } from "../../utils/compareTimeStamps";

const Table4k = ({
  clients,
  format,
  query,
  sendMessage,
  setDefaultMessage,
  defaultMessage,
  messagetoSend,
  setMessagetoSend,
  messageError,
  phone,
  setPhone,
  open,
  setOpen,
}) => {
  console.log("clients4kkk", clients);

  const [currentUser, setCurrentUser] = useState({});

  const prepareMessage = (user) => {
    setDefaultMessage(`Si ta TV fonctionne pas voici les codes  
USER:${user.Username}
MDP:${user.Password}
URL:*${process.env.REACT_APP_FOURK_PORTAL}* sans *
AIDE: https://tinyurl.com/winde18`);
  };

  console.log("clients", clients);
  return (
    <>
      {clients
        ?.filter((val) => {
          if (val === "") {
            return val;
          } else if (
            val?.Username?.toLowerCase()?.includes(query?.toLowerCase())
          ) {
            return val;
          } else if (
            val?.Password?.toLowerCase()?.includes(query?.toLowerCase())
          ) {
            return val;
          }
          return null;
        })
        .map((user) => (
          <tr key={user.id} className="bg-gray-600">
            <td
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 flex gap-3"
              key={user.id}
            >
              <div className="flex">
                <ChatIcon
                  className="h-8 text-teal-300 hover:text-teal-500 cursor-pointer"
                  onClick={() => {
                    // sendMessage(user);
                    setOpen(true);
                    setCurrentUser(user);
                    prepareMessage(user);
                    setPhone(user.Username || user.username);
                  }}
                />
                <button
                  onClick={() => {
                    toast.success("Copié avec Succès", {
                      position: "top-center",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }}
                >
                  <CopyToClipboard text={defaultMessage}>
                    <ClipboardCopyIcon
                      // onCopy={"hello"}
                      className="h-8 text-teal-300 hover:text-teal-500 cursor-pointer"
                    />
                  </CopyToClipboard>
                </button>
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              <p className="text-teal-500 truncate group-hover:text-gray-900 ">
                {user.Username}
              </p>
            </td>

            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-cyan-300 ">
              <span className="font-medium">{user.Password}</span>
            </td>
            {/* <td>{format(user.enabled)}</td> */}

            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-cyan-300">
              {moment(user.Expire_Date).format("DD/MM/YYYY") <
              moment().format("DD/MM/YYYY") ? (
                <span className="text-red-400">
                  {" "}
                  expiré le {moment(user.Expire_Date).format("DD/MM/YYYY")}
                </span>
              ) : (
                <p>
                  <span> {moment(user.Expire_Date).fromNow()} </span>
                  le {moment(user.Expire_Date).format("DD/MM/YYYY")}
                </p>
              )}
            </td>
            <td className=" px-6 py-4 text-center whitespace-nowrap text-sm text-cyan-300 md:block">
              {user.Note}
            </td>
          </tr>
        ))}

      <ModalSentPanel
        open={open}
        setOpen={setOpen}
        phone={phone}
        setPhone={setPhone}
        messageError={messageError}
        messagetoSend={messagetoSend}
        setMessagetoSend={setMessagetoSend}
        sendMessage={sendMessage}
        defaultMessage={defaultMessage}
        currentUser={currentUser}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Table4k;
