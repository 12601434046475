import { images } from "../utils/image";

export const notice = [
  {
    id: images.img1,
    description: `1 - A partir de la page d'accueil, Aller dans "REGLAGES" a droite`,
  },
  {
    id: images.img2,
    description: ` 2 - Taper "Ok sur le rond milieu de la télécommande`,
  },
  { id: images.img3, description: `3 - Séléctionner Ma fire Tv` },
  {
    id: images.img4,
    description: ` 4 - Si vous avez options pour les développeurs clicker ok sinon
  Aller dans "A propos" puis cliquer plusieurs fois sur Ok successivement afin de voir un menu en bas apparaitre une fois que vous lisez le message que vous ête développeur alors faite retour et clicker sur options pour les  Developpers `,
  },
  {
    id: images.img5,
    description: `5 - aller dans app inconnues et faire ok`,
  },
  {
    id: images.img6,
    description: `6 - Activer les applications de sources inconnues`,
  },
  { id: images.img7, description: `7 - Revenir à l'accueil` },
];
