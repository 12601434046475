import React from "react";
export default function LoaderBig() {
  return (
    <div className="relative  sm:py-2  mt-0">
      <div className="mx-auto max-w-md px-2">
        <div
          className="flex flex-wrap gap-3 justify-center"
          style={{ maxWidth: 800, justifyContent: "center" }}
        >
          <div
            style={{ borderTopColor: "transparent" }}
            className="w-10 h-10 border-4 border-red-500 border-double rounded-full animate-spin"
          />
        </div>
      </div>
    </div>
  );
}
