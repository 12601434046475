import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/solid";

import z10pro2 from "../../assets/z10pro2.jpg";
import z10pro from "../../assets/z10pro.jpg";
import netflix from "../../assets/netflix.png";
import disney from "../../assets/disney.png";
import canalplus from "../../assets/canalplus.png";
import bein from "../../assets/bein.png";
import skysport from "../../assets/skysport.png";
import foxsports from "../../assets/foxsports.png";
import rds from "../../assets/rds.png";
import dorceltv from "../../assets/dorceltv.png";
import superEcran from "../../assets/superEcran.png";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import photo from "../../assets/firetv/1.png";

const Header = ({ scrollButton }) => {
  const [dataForm, setDataForm] = useState({
    firstName: "",
    lastName: "",
    email: "",

    phone: "",
  });

  const [messageSuccess, setMessageSuccess] = useState(false);

  const handlechange = (index, value) => {
    setDataForm({
      ...dataForm,
      [index]: value,
    });
  };

  const createAccount = async (e) => {
    e.preventDefault();
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/client`,
      // withCredentials: true,
      data: dataForm,
    })
      .then((res) => {
        console.log("res", res);
        setDataForm({ firstName: "", lastName: "", email: "", phone: "" });
        localStorage.setItem("phone", dataForm.phone);

        setMessageSuccess(true);
        toast.success(" 🚀 Félicitations inscriptions approuvée  ", {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDataForm({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      })
      .catch((err) => console.log("err", err));
  };

  return (
    <div>
      <div className="relative pt-6 pb-16 sm:pb-24">
        <main className="mt-5 sm:mt-5">
          <div className="mx-auto max-w-7xl">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="px-4 sm:px-2 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-8 lg:text-left lg:flex lg:items-center">
                <div>
                  <div
                    onClick={scrollButton}
                    className="inline-flex cursor-pointer items-center text-white bg-red-500 rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
                  >
                    <span className="ml-4 text-lg uppercase">
                      ✅ S'inscrire
                    </span>
                    <ChevronRightIcon
                      className="ml-2 w-10 h-10 text-teal-400 rotate-90"
                      aria-hidden="true"
                    />
                  </div>

                  <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
                    <span className="md:block">Firestick , Formuler Z10</span>
                    <br />
                    <span className="text-cyan-400 md:block md:-mt-8">
                      N°1 au 🇨🇦 , 🇺🇸 & 🇫🇷
                    </span>
                  </h1>
                  <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    Le service est disponible <br />
                    🇨🇦 🇫🇷 🇺🇸 🇨🇭 🇮🇹 🇲🇦 🇬🇳 🇸🇳 🇨🇲 🇨🇬 🇨🇮 🇨🇩 🇨🇻 🇹🇷 🇷🇴 ,...
                  </p>
                  <p className="mt-8 text-sm text-white uppercase tracking-wide font-semibold sm:mt-5">
                    Profitez d'une expérience inoubliable a partager avec vos
                    proches !
                  </p>
                  <div className="mt-0 w-full sm:mx-auto sm:max-w-lg lg:ml-0 ">
                    <div className="flex flex-wrap items-start justify-between mt-2">
                      <>
                        <div className="flex justify-center px-1 mt-2 ">
                          <img
                            className="h-9 sm:h-10"
                            src={netflix}
                            alt="Tuple"
                          />
                        </div>
                        <div className="flex justify-center px-1 mt-2">
                          <img
                            className="h-9 sm:h-10"
                            src={disney}
                            alt="Workcation"
                          />
                        </div>
                        <div className="flex justify-center px-1 mt-2">
                          <img
                            className="h-9 sm:h-8"
                            src={canalplus}
                            alt="StaticKit"
                          />
                        </div>
                        <div className="flex justify-center px-1 mt-2">
                          <img
                            className="h-9 sm:h-10"
                            src={bein}
                            alt="StaticKit"
                          />
                        </div>
                        <div className="flex justify-center px-1 mt-2">
                          <img
                            className="h-9 sm:h-10"
                            src={skysport}
                            alt="StaticKit"
                          />
                        </div>
                        <div className="flex justify-center px-1 mt-2">
                          <img
                            className="h-9 sm:h-10"
                            src={foxsports}
                            alt="StaticKit"
                          />
                        </div>
                        <div className="flex justify-center px-1 mt-2">
                          <img
                            className="h-9 sm:h-10"
                            src={rds}
                            alt="StaticKit"
                          />
                        </div>
                        <div className="flex justify-center px-1 mt-2">
                          <img
                            className="h-9 sm:h-10"
                            src={dorceltv}
                            alt="StaticKit"
                          />
                        </div>
                        <div className="flex justify-center px-1 mt-2">
                          <img
                            className="h-9 sm:h-10"
                            src={superEcran}
                            alt="StaticKit"
                          />
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
                <div className="bg-white sm:max-w-lg sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
                  <img
                    src={photo}
                    className=" mx-auto rounded-0xl aspect-auto "
                    alt="salon"
                  />
                  {/* <div className="px-4 py-8 sm:px-10">
                    <div>
                      <p className="text-sm font-medium text-gray-700">
                        S'inscrire pour les bons plans
                      </p>

                      <div className="mt-1 grid grid-cols-3 gap-3"></div>
                    </div>

                    <div className="mt-6">
                      <form action="#" method="POST" className="space-y-6">
                        <div className="grid grid-cols-2 space-x-2">
                          <div>
                            <label htmlFor="name" className="sr-only">
                              Prénom
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              autoComplete="name"
                              placeholder="Prénom"
                              required
                              value={dataForm.name}
                              className="block w-full shadow-sm focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm border-gray-300 rounded-md text-stone-700"
                              onChange={(e) => {
                                handlechange("firstName", e.target.value);
                                console.log(e.target.value);
                              }}
                            />
                          </div>
                          <div>
                            <label htmlFor="name" className="sr-only">
                              Nom
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              autoComplete="name"
                              placeholder="Nom"
                              required
                              value={dataForm.name}
                              className="block w-full shadow-sm focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm border-gray-300 rounded-md text-stone-700"
                              onChange={(e) => {
                                handlechange("lastName", e.target.value);
                                console.log(e.target.value);
                              }}
                            />
                          </div>
                        </div>

                        <div>
                          <label htmlFor="email" className="sr-only">
                            email
                          </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            autoComplete="email"
                            placeholder="Email"
                            required
                            className="block w-full shadow-sm focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm border-gray-300 rounded-md text-stone-700"
                            onChange={(e) => {
                              handlechange("email", e.target.value);
                              console.log(e.target.value);
                            }}
                          />
                        </div>
                        <div>
                          <label htmlFor="mobile" className="sr-only">
                            You Phone
                          </label>
                          <input
                            type="tel"
                            name="tel"
                            id="tel"
                            autoComplete="tel"
                            placeholder="Téléphone"
                            required
                            maxLength={10}
                            className="block w-full shadow-sm focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm border-gray-300 rounded-md text-stone-700"
                            onChange={(e) => {
                              handlechange("phone", e.target.value);
                              console.log(e.target.value);
                            }}
                          />
                        </div>

                        <div>
                          <button
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                            onClick={createAccount}
                          >
                            S'inscrire !
                          </button>
                        </div>
                      </form>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ToastContainer
        style={{ width: "400px" }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Header;
