import React from "react";
import salon from "../../assets/salon.jpg";
import ios from "../../assets/iosapp.png";
import windows from "../../assets/windows.png";
import googleplay from "../../assets/googleplay.png";
import mac from "../../assets/mac.png";
import GoogleAds from "./GoogleAds";

const Presentation = () => {
  return (
    <div className="">
      <div className="text-center text-gray-300">
        <h1 className="text-3xl capitalize"> Nos produits </h1>
        <p className="mt-5 text-lg leading-loose">
          {/* " Iptv service is a way to watch your favorite programs wherever you
          are with just your 4G or internet connection " */}
          Nous vendons des boitiers firestick TV d'Amazon, Formuler Z10 et Z10
          Pro...
          <br />
          Tous nos produits sont neufs et Garanties
        </p>
        <p className="mt-5 uppercase mb-2 text-cyan-300 text-lg">
          {" "}
          Télécharger votre Logiciel en cliquant sur les liens !
        </p>
      </div>

      <div className="md:flex md:justify-evenly md:flex-row flex flex-col justify-center border-2 border-cyan-300 p-5 rounded-2xl m-4 lg:m-10 ">
        <div
          onClick={() =>
            window.open(
              "https://apps.apple.com/ca/app/smarters-player-lite/id1628995509?l=fr"
            )
          }
          className="cursor-pointer mx-auto"
        >
          <img alt="ios" src={ios} className=" p-2 " />
        </div>
        <div
          onClick={() =>
            window.open(
              "http://play.google.com/store/apps/details?id=com.nst.smartersplayer"
            )
          }
          className="cursor-pointer mx-auto"
        >
          <img alt="googleplay" src={googleplay} className=" p-2 " />
        </div>
        <div
          onClick={() =>
            window.open(
              "https://www.iptvsmarters.com/download?download=windows"
            )
          }
          className="cursor-pointer mx-auto"
        >
          <img alt="windows" src={windows} className=" p-2 " />
        </div>
        <div
          onClick={() =>
            window.open("https://www.iptvsmarters.com/download/?download=mac")
          }
          className="cursor-pointer mx-auto"
        >
          <img alt="mac" src={mac} className=" p-2 " />
        </div>
      </div>
      {/* <div className="grid grid-col-2 justify-center space-x-3 m-5 bg-slate-800 p-5 rounded-3xl">
        <img
          className="h-full object-cover w-min-full rounded-2xl "
          src={salon}
          alt="salon"
        />
      </div> */}
    </div>
  );
};

export default Presentation;
