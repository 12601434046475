import { Dialog, Transition } from "@headlessui/react";
import { ChatIcon } from "@heroicons/react/solid";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { formatPhone } from "../../utils/formatPhone";
import axios from "axios";

const ModalSentPanel = ({
  open,
  setOpen,
  phone,
  setPhone,
  messageError,
  messagetoSend,
  setMessagetoSend,
  sendMessage,
  defaultMessage,
  currentUser,
  expirateDate,
}) => {
  const cancelButtonRef = useRef(null);
  console.log("currentUser", currentUser);

  const [template, setTemplate] = useState();

  const getTemplate = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/templates`,
      // withCredentials: true,
    }).then((res) => {
      setTemplate(res.data.template);
    });
  };

  useEffect(() => {
    setMessagetoSend(defaultMessage);
    getTemplate();
  }, [open]);

  const handleTemplate = (e) => {
    const selectElement = document.getElementById("plan"); // Récupère l'élément select par son id
    const selectedOption = selectElement.options[selectElement.selectedIndex]; // Récupère l'option sélectionnée
    const selectedText = selectedOption.textContent; // Récupère le texte de l'option sélectionnée
    console.log("=====", selectedText);

    setMessagetoSend(e.target.value);
  };

  console.log("defaultMessage", defaultMessage);

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-2 w-full pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-2 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <p className="text-center mb-4 bg-orange-500 mx-3 text-white rounded-lg">
                    {" "}
                    Expiration : {expirateDate}
                  </p>
                  <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-100">
                    <ChatIcon
                      className="h-10 w-10 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Envoi de Message à {formatPhone(phone)}
                    </Dialog.Title>
                    <div className="mt-2 grid grid-col-2 px-2">
                      <div className="col-span-1">
                        <div className="col-span-1 mt-2 mb-2 "> </div>

                        <div className="col-span-1">
                          <label className="ml-px pl-4 block text-sm font-medium text-gray-700">
                            Tél
                          </label>
                          <input
                            id="phone"
                            type="tel"
                            maxLength={10}
                            placeholder="changer le numero ici"
                            onChange={(e) => {
                              console.log("e.target.value", e.target.value);
                              setPhone(e.target.value);
                            }}
                            className="py-2 px-4 text-sm block w-full shadow-sm text-warm-gray-900 focus:ring-cyan-500 focus:border-cyan-500 border border-warm-gray-300 rounded-md text-slate-800"
                          />
                        </div>
                        <div className="col-span-1 mt-3">
                          <label className="ml-px pl-4 block text-sm font-medium text-gray-700">
                            Message
                          </label>
                          <p> {messageError && messageError}</p>

                          <select
                            id="plan"
                            name="plan"
                            autoComplete="plan"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            onChange={handleTemplate}
                            value={messagetoSend}
                          >
                            <option value={defaultMessage} label="Default">
                              {" "}
                              Default{" "}
                            </option>

                            {template &&
                              template.map((item) => (
                                <option value={item.body}>
                                  {" "}
                                  {item.title}{" "}
                                </option>
                              ))}
                          </select>
                          <textarea
                            id="message"
                            name="message"
                            rows="5"
                            value={messagetoSend}
                            type="text"
                            maxLength={160}
                            onChange={(e) => setMessagetoSend(e.target.value)}
                            className="py-2 px-4 text-sm block w-full shadow-sm text-warm-gray-900 focus:ring-cyan-500 focus:border-cyan-500 border border-warm-gray-300 rounded-md text-slate-800"
                          />
                          <div className="text-sm mt-2">
                            {messagetoSend?.length} / 160 characters Max
                          </div>
                          {messagetoSend?.length === 160 && (
                            <div className="text-red-500 text-sm">
                              {" "}
                              SMS trop long !
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-teal-600 text-base font-medium text-white hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:col-start-2 sm:text-sm"
                    onClick={sendMessage}
                  >
                    Send
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(!open)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default ModalSentPanel;
