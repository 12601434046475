/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { ChatIcon } from "@heroicons/react/solid";
import { dataMessages } from "../../shared/DataMessages";

export default function SmsModalTest({ open, setOpen, dataModal }) {
  const messageOne = `- Username : XXXXX 
- Password : XXXX 
- Server: (http://youmitof.com) 
without () sans les () 
Download the App
https://tv-wow.com,   
Tv-Wow,
+1.514.222.6801
Do Not Reply this Message`;

  // const messageTwo = `- Hello, Thanks to trust our services
  // you can earn 5$ if you refer a fiends or memeber Family
  // You can transfer this message and request a 24h Test https://tv-wow.com,
  // Team Tv-Wow,
  // contact@tv-wow.com
  // +1.514.222.6801
  // Not Reply this Message`;

  // const [message, setMessage] = useState(messageOne);
  const [smsSend, setSmsSend] = useState();
  const [phone, setPhone] = useState(dataModal);
  const [message, setMessage] = useState(dataMessages[1].title);
  const [titleSelected, settitleSelected] = useState(null);
  const [selected, setSelected] = useState("-");

  // console.log("number datam >>", dataModal);
  // console.log("phone >>", phone);
  // console.log("message >>", messageOne);

  const cancelButtonRef = useRef(null);

  const handleSelect = (event) => {
    setSelected(event.target.value);
    if (event.target.value === "Default") {
      setMessage(dataMessages[0].message);
      settitleSelected(dataMessages[0].title);
    } else if (event.target.value === "Activation") {
      setMessage(dataMessages[1].message);
      settitleSelected(dataMessages[1].title);
    } else if (event.target.value === "Refer") {
      setMessage(dataMessages[2].message);
      settitleSelected(dataMessages[2].title);
    } else if (event.target.value === "New") {
      setMessage(dataMessages[3].message);
      settitleSelected(dataMessages[3].title);
    } else if (event.target.value === "Renew") {
      setMessage(dataMessages[4].message);
      settitleSelected(dataMessages[4].title);
    } else if (event.target.value === "Demande Test") {
      setMessage(dataMessages[5].message);
      settitleSelected(dataMessages[5].title);
    }
  };

  const handleSend = async () => {
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/message/sendsms`,
      data: {
        body: message,
        // from: "+12184384469",
        from: process.env.REACT_APP_PHONE,
        to: `+1${dataModal}`,
      },
    })
      .then((res) => {
        // history.push("/admin/addclient");
        console.log(res.status);
        if (res.status === 200) {
          setSmsSend("ok");
          localStorage.setItem(
            "sms-send",
            `${dataModal.phoneNumber} ${message}`
          );
        } else return null;

        setTimeout(() => {
          setSmsSend(null);
        }, 2000);
      })
      .catch((err) => console.log("Errorr", err));
  };

  const textSuccess =
    smsSend === "ok" ? (
      <p className="bg-green-300 text-black p-1 text-lg uppercase rounded-xl">
        Message envoyé envoyé avec succès{" "}
      </p>
    ) : null;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-100">
                  <ChatIcon
                    className="h-10 w-10 text-green-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {/* Message success */}
                    {textSuccess}
                  </Dialog.Title>
                  <div className="mt-2 grid grid-col-2">
                    <div className="col-span-1">
                      <label
                        htmlFor="Month"
                        className="ml-px pl-4 block text-sm font-medium text-gray-700"
                      >
                        Phone Number
                      </label>
                      <div className="mt-1">
                        <input
                          type="number"
                          maxLength={12}
                          name="phone"
                          id="phone"
                          defaultValue={dataModal}
                          value={phone}
                          className="shadow-sm text-center focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-lg"
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>

                      <div className="col-span-1 mt-2 mb-2 ">
                        <select
                          id="message"
                          name="message"
                          value={selected}
                          autoComplete="message"
                          className="  focus:ring-cyan-500 focus:border-cyan-500 h-8 w-36 py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                          onChange={(event) => handleSelect(event)}
                        >
                          <option> - </option>
                          {dataMessages.map((op, index) => (
                            <option key={index}>{op.title}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-span-1">
                        <label
                          htmlFor="Month"
                          className="ml-px pl-4 block text-sm font-medium text-gray-700"
                        >
                          Message
                        </label>
                        <p className="text-sm text-left">
                          Caractères Restant :{250 - message.length}
                        </p>
                        <div className="mt-1">
                          <textarea
                            type="text"
                            name="price"
                            id="price"
                            rows={8}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-lg"
                            placeholder=""
                            value={message}
                            onChange={(e) => {
                              setMessage(e.target.value);
                              //   console.log("message", message);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                  onClick={() => {
                    console.log("send");
                    handleSend();
                    setTimeout(() => {
                      setOpen(false);
                      setSmsSend(null);
                    }, 1500);
                  }}
                >
                  Send
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => setOpen(!open)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
