import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import { Link, useHistory, useParams } from "react-router-dom";
import { Switch } from "@headlessui/react";

const UpdateFormUser = () => {
  let { id } = useParams();
  let history = useHistory();
  // const [startDate, setStartDate] = useState();
  const [dataUser, setDataUser] = useState();
  const [errors, setErrors] = useState();
  const [formData, setformData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    repeatPassword: "",
    phone: "",
    email: "",
    isAdmin: "",
  });

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [enabled, setEnabled] = useState();

  function incr_date(date_str) {
    if (date_str) {
      var parts = date_str.split("-");
      var dt = new Date(
        parseInt(parts[0], 10), // year
        parseInt(parts[1], 10) - 1, // month (starts with 0)
        parseInt(parts[2], 10) // date
      );
      dt.setDate(dt.getDate() + 365);
      parts[0] = "" + dt.getFullYear();
      parts[1] = "" + (dt.getMonth() + 1);
      if (parts[1].length < 2) {
        parts[1] = "0" + parts[1];
      }
      parts[2] = "" + dt.getDate();
      if (parts[2].length < 2) {
        parts[2] = "0" + parts[2];
      }
      return parts.join("-");
    } else return null;
  }

  // console.log(typeof parseInt(formData.startSubscribe), "type of ");
  const dateEnd = incr_date(dataUser?.startSubscribe);
  // console.log(typeof dataUser?.startSubscribe);
  // console.log(dateEnd, "new data");

  const handelChange = (index, value) => {
    setDataUser({
      ...dataUser,
      [index]: value,
    });

    console.log("datauser <<", dataUser);
  };

  const getUser = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/user/${id}`,
      withCredentials: true,
    }).then((res) => {
      setDataUser(res.data.user);
      console.log(res.data.user);
      setEnabled(res.data.user.isAdmin);
    });
  };

  useEffect(() => {
    getUser();
  }, []);

  const handleUpdate = async () => {
    await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/api/user/${id}`,
      withCredentials: true,
      data: {
        firstName: dataUser.firstName,
        lastName: dataUser.lastName,
        password: dataUser.password,
        phone: dataUser.phone,
        email: dataUser.email,
        isAdmin: enabled,
      },
    })
      .then((res) => {
        console.log("réponse : ", res);
        console.log("Mise a jour ok : ", formData);
        setErrors("");
        // setformData({
        //   firstName: "",
        //   lastName: "",
        //   password: "",
        //   phone: "",
        //   email: "",
        // });
        toast.success("Update Success", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push("/admin/");
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  console.log("dataUser.isAdmin", dataUser?.isAdmin);
  return (
    <div>
      {dataUser ? (
        <div className="mt-10 sm:mt-0  pb-5 mb-5">
          <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-2">
                    {/* Name */}
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nom
                      </label>
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        defaultValue={dataUser?.lastName}
                        onChange={(e) => handelChange("name", e.target.value)}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Prénom
                      </label>
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        defaultValue={dataUser?.firstName}
                        onChange={(e) => handelChange("name", e.target.value)}
                      />
                    </div>
                    {/* Phone Number */}
                    <div className="col-span-6 sm:col-span-3 ">
                      <label
                        htmlFor="phone-number"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Phone Number
                      </label>

                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 flex items-center">
                          <label htmlFor="phone" className="sr-only">
                            Phone Number
                          </label>
                        </div>
                        <input
                          type="tel"
                          name="phone-number"
                          id="phone-number"
                          className="focus:ring-cyan-500 focus:border-cyan-500 block w-full pl-4 sm:text-sm border-gray-300 rounded-md"
                          maxLength={10}
                          defaultValue={dataUser?.phone}
                          onChange={(e) => {
                            handelChange("phone", e.target.value);
                            console.log(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="ibrahima@gmail.com"
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        defaultValue={dataUser?.email}
                        onChange={(e) => handelChange("email", e.target.value)}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        autoComplete="password"
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        // value={formData?.password}
                        onChange={(e) =>
                          handelChange("password", e.target.value)
                        }
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Repeat Password
                      </label>
                      <input
                        type="password"
                        name="repeatPassaword"
                        id="repeatPassaword"
                        autoComplete="password"
                        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        // value={formData?.repeatPassaword}
                        onChange={(e) =>
                          handelChange("repeatPassword", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                {enabled ? (
                  <p className="text-center mb-2 text-sm text-cyan-500">
                    is Admin
                  </p>
                ) : (
                  <p className="text-center mb-2 text-sm text-red-500">
                    Not Admin
                  </p>
                )}
                <div className="flex justify-center mb-5 ">
                  <Switch
                    checked={dataUser.isAdmin}
                    onChange={() => setEnabled(!enabled)}
                    className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className="pointer-events-none absolute bg-white w-full h-full rounded-md"
                    />
                    <span
                      aria-hidden="true"
                      className={classNames(
                        enabled ? "bg-cyan-600" : "bg-gray-200",
                        "pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200"
                      )}
                    />
                    <span
                      aria-hidden="true"
                      className={classNames(
                        enabled ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200"
                      )}
                    />
                  </Switch>
                </div>

                <div className="px-4 py-3 bg-gray-50 text-center lg:text-right sm:px-6 md:px-6  space-y-2 lg:space-x-3">
                  <Link
                    to={"/admin/"}
                    className="inline-flex  sm:mr-5  w-48 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                  >
                    Annuler
                  </Link>
                  <button
                    type="submit"
                    className="inline-flex  w-48 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                    onClick={() => handleUpdate()}
                  >
                    Enregistrer
                  </button>
                </div>
              </div>
            </div>
            <div> {errors && <div> {errors} </div>}</div>
          </div>
          {/* <ListUsers Users={dataUsers} handelDelete={handelDelete()} /> */}
        </div>
      ) : (
        <p className="text-center mt-25"> ... Loading </p>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default UpdateFormUser;
