import React, { useEffect, useState } from "react";
import { ChatIcon, TrashIcon } from "@heroicons/react/solid";
import axios from "axios";
import moment from "moment";
import "moment/locale/fr";
import SmsModalTest from "./SmsModalTest";
import SeeDetailSms from "./SeeDetailSms";
import { EyeIcon } from "@heroicons/react/outline";
import Loader from "../../utils/Loader";

const TableSms = ({
  allMessages,
  deleteMessage,
  loading,
  totalCount,
  currentPage,
  pageSize,
}) => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [dataModal, setDataModal] = useState();
  const [dataModalDetail, setDataModalDetail] = useState();

  console.log("pageSize :>> ", pageSize);
  console.log("currentPage :>> ", currentPage);

  useEffect(() => {
    console.log("allMessages", allMessages);
  });

  return (
    <div className="mx-5">
      <div>
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              {/* <h1 className="text-xl font-semibold text-gray-900">
                Listes Messages
              </h1> */}
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              {/* <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                Add user
              </button> */}
            </div>
          </div>

          <div className="mt-8 flex flex-col">
            <p className="mt-2 mb-2 text-md text-cyan-700 font-semibold">
              {totalCount} messages envoyés
            </p>
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  {!allMessages ? (
                    <span className="text-center"> ... Loading </span>
                  ) : (
                    <table className="lg:min-w-full divide-y divide-gray-300 w-64 ">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                          >
                            id
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                          >
                            Send
                          </th>

                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Date Reçu
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                          >
                            Numéro
                          </th>
                          {/* <th
                            scope="col"
                            className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                          >
                            Message
                          </th> */}

                          {/* <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          >
                            <span className="sr-only">Edit</span>
                          </th> */}
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                          >
                            Delete
                          </th>
                        </tr>
                      </thead>

                      <tbody className="divide-y divide-gray-200 bg-white">
                        {allMessages?.map((m, index) => (
                          <tr key={m._id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {currentPage === 1
                                ? index + 1
                                : index + currentPage * pageSize - 10 + 1}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                              <button className="text-cyan-600 hover:text-cyan-900 ">
                                <ChatIcon
                                  className="h-7"
                                  onClick={(id) => {
                                    setOpen(true);
                                    setDataModal(m.phoneNumber);
                                  }}
                                />
                              </button>
                              <button className="text-cyan-600 hover:text-cyan-900 ">
                                <EyeIcon
                                  className="h-7"
                                  onClick={(id) => {
                                    setOpen2(true);
                                    setDataModalDetail(m);
                                  }}
                                />
                              </button>
                            </td>

                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {moment(m.createdAt).format("LLL")}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {m.to}
                            </td>
                            <td className="whitespace-nowrap  px-3 py-4 text-left text-sm text-gray-500 text-ellipsis overflow-hidden ... ">
                              {m.title}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6">
                              {!loading ? (
                                <button className="text-red-600 hover:text-red-900">
                                  <TrashIcon
                                    className="h-7"
                                    onClick={() => deleteMessage(m._id)}
                                  />
                                </button>
                              ) : (
                                <Loader />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <SmsModalTest open={open} setOpen={setOpen} dataModal={dataModal} />
        <SeeDetailSms
          open={open2}
          setOpen={setOpen2}
          dataModal={dataModalDetail}
        />
      </div>
    </div>
  );
};

export default TableSms;
