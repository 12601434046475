export const dataMessages = [
  {
    id: 0,
    title: "Default",
    message: `Visites www.tv-wow.com pour plus d information sur l installation. Ne pas repondre`,
  },
  {
    id: 1,
    title: "Activation",
    message: `Username: XXXXX - Password: XXXX -Server:(http://yamiklo.com), +1.514.222.6801`,
  },
  {
    id: 2,
    title: "Refer",
    message: `Salut,
Merci d'avoir choisi notre formule iPTV 4ott l un des meilleurs serveurs. Gagne 5$ en recommandant chaque Ami(e) ou membre de ta famille - Reponse +1.514.222.6801`,
  },
  {
    id: 3,
    title: "Offre",
    message: `Nouveau IPTV En 4k disponible - 1 mois : 30$CAD - 3 Mois : 75$CAD - 6 mois : 115$CAD - 12 mois : 150$ CAD
Reponse :+1.514.222.6801`,
  },
  {
    id: 4,
    title: "Expiration",
    message: `Salut, 
ton abonnement iptv va expirer, 
pour continuer le service texte au 514.222.6801`,
  },
  {
    id: 5,
    title: "Demande Test",
    message: `🎉Bravo, Nous te faisons parvenir les informations le plus rapidement. Ne pas repondre - Tv-Wow`,
  },
  {
    id: 6,
    title: "probleme",
    message: `les serveurs iptv ont subits des attaques aucune date de remise en service,on propose un autre serveur temporaire pour 24h a renouveler si besoin. Merci de nous écrire
    tv-wow.com/help`,
  },
  {
    id: 7,
    title: "new server",
    message: `Utilisateur : wind36
    Password : s6wYU6a
    serveur: *http://azertytv.com:2103* sans *
    Ce code est temporaire 
    La vidéo explicative  tv-wow.com/help`,
  },
  {
    id: 8,
    title: "Magnum",
    message: `Utilisateur : wind36
    Password : s6wYU6a
    *http://azertytv.com:2103* sans *
    La vidéo explicative  tv-wow.com/help`,
  },
];
