import React, { useEffect, useState } from "react";
import axios from "axios";
import CardHeader from "../components/admin/CardHeader";
import { Auth } from "../context/AuthContext";
import {
  UserIcon,
  ShoppingBagIcon,
  CheckCircleIcon,
} from "@heroicons/react/solid";

const Dashboard = () => {
  const id = localStorage.getItem("id");
  const user = Auth();

  const [infoUsers, setInfoUsers] = useState();
  const [allUsers, setAllUsers] = useState();
  const [infoClients, setInfoClients] = useState();
  const [infoProducts, setInfoProducts] = useState();

  const array = [];

  const getAllClients = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/client`,
      withCredentials: true,
    })
      .then((res) => {
        // console.log("res.clients", res.data);
        setInfoClients(res.data.clients);
        console.log("res.data.clients)", res.data.clients);
      })
      .catch((err) => console.log("err", err));
  };

  const getInfoUsers = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/user/${id}`,
      withCredentials: true,
    })
      .then((res) => {
        // console.log("res.data", res.data);
        setInfoUsers(res.data);
      })
      .catch((err) => console.log("err", err));
  };

  const getUsers = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/user/`,
      withCredentials: true,
    })
      .then((res) => {
        console.log("allusers", res.data.users);
        setAllUsers(res.data.users);
      })
      .catch((err) => console.log("err", err));
  };

  const getProducts = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/product`,
    }).then((res) => {
      setInfoProducts(res.data.products);
      // console.log(res.data.products);
    });
  };

  useEffect(() => {
    console.log("userContext Dash", user);
    getInfoUsers();
    getAllClients();
    getProducts();
    getUsers();
  }, []);

  let stats = [];
  stats.push(
    {
      name: "Products",
      icon: ShoppingBagIcon,
      amount: infoProducts?.length,
      url: "/admin/products",
    },
    {
      name: "Clients",
      icon: CheckCircleIcon,
      amount: infoClients?.length,
      url: "/admin/addclient",
    },
    {
      name: "Users",
      icon: UserIcon,
      amount: allUsers?.length,
      url: "/admin/",
    }
  );

  const name = JSON.parse(localStorage.getItem("name"));
  // console.log(name);

  return (
    <div className="bg-gray-800 min-h-screen text-white">
      <main className="flex-1 ">
        <div className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-5xl font-semibold text-white text-center mt-5">
              {localStorage.getItem("name")
                ? "Welcome " + name + " !"
                : "Welcome !"}
            </h1>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-5 ">
            <CardHeader stats={stats} />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
