import React from "react";
import FormClient from "../components/admin/FormClient";

const AddClient = () => {
  return (
    <div className="pt-5 m-2 bg-gray-800 ">
      <main className="flex-1">
        <h1 className="text-2xl text-center text-teal-500 pb-4">
          {" "}
          Ajouter un Nouveau Client{" "}
        </h1>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-4">
          <FormClient />
        </div>
      </main>
    </div>
  );
};

export default AddClient;
