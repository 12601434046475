/* eslint-disable jsx-a11y/no-redundant-roles */
import axios from "axios";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import { dataMessages } from "../shared/DataMessages";
import { addCountryCode } from "../utils/addCountryCode";

export default function BulkSms() {
  const [message, setMessage] = useState(dataMessages[6].message);

  const [statusSend, setStatusSend] = useState(false);

  const [clients, setClients] = useState([]);
  const [listPhone, setListPhone] = useState([]);
  const [listUsers, setListUsers] = useState([]);
  const [phones, setPhones] = useState();

  const getAllClients = async () => {
    let _phones = [];
    let _users = [{ name: "", phone: "" }];
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/client`,
      withCredentials: true,
    })
      .then((res) => {
        // console.log("res.clients", res.data);
        setClients(res.data.clients);
        console.log("res.data.clients)", res.data.clients);
        if (res.data.clients) {
          res.data.clients.map((item) =>
            _users.push({ phone: item.phone, name: item.name })
          );
          res.data.clients.map((item) => _phones.push(item.phone));
          setPhones(_phones);
        }
        console.log("_users", _users);
        setListUsers(_users);
        // setListUsers(addCountryCode(_phones));
      })
      .catch((err) => console.log("err", err));
  };

  const sendBulk = async () => {
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/message/bulksms`,
      data: { numbers: listPhone, body: message },
    })
      .then((res) => {
        // history.push("/admin/addclient");

        toast.success("success! Message was sent successfully !!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          setStatusSend(false);

          setMessage("");
        }, 1000);
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllClients();
  }, []);

  return (
    <div className="bg-gray-800 mb-5 min-h-screen">
      <div className="p-2">
        <div className="max-w-7xl mx-auto pt-2 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:flex-col sm:align-center">
            <h1 className="text-4xl font-extrabold text-cyan-400 text-center">
              Envoi des Bulk Messages
            </h1>
            <p className="mt-1 text-md text-cyan-500 text-center">
              Ce système permet d'envoyé a chaque abonné un message de bienvenue
            </p>
          </div>
        </div>
      </div>

      <div className="mt-5  ">
        <div className="lg:mx-10 mx-5">
          <div className="flex justify-center">
            <label htmlFor="phone" className="sr-only">
              Phone Number
            </label>
            <select
              id="phone"
              name="phone"
              autoComplete="phone"
              className="focus:ring-cyan-500 lg:w-3/5 mx-auto focus:border-cyan-500 h-64 py-0 pl-3 pr-7 border-transparent bg-transparent text-cyan-300 sm:text-sm rounded-md"
              multiple
              onChange={(e) => {
                const selectedOptions = Array.from(e.target.selectedOptions);
                const selectedValues = selectedOptions.map(
                  (option) => option.value
                );
                console.log("Selected values:", selectedValues);
                setListPhone(addCountryCode(selectedValues));
                console.log("listPhone=>>", listPhone);
              }}
            >
              {listUsers.map((user) => (
                <option value={user.phone}>
                  {user.name ? user.name : user.phone}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-5">
            {listPhone.length > 0 ? (
              <div className="text-red-500 text-center text-sm uppercase">
                Sélection faite
              </div>
            ) : (
              <div className="text-sm text-center text-stone-400">
                Aucune Selection faite
              </div>
            )}
          </div>
          <div className="flex justify-center">
            <button
              onClick={() => setListPhone(phones)}
              className="w-full lg:w-1/4 mt-5 justify-center rounded-md border border-transparent  px-4 py-2 bg-blue-600 shadow-blue-500/50 shadow-lg text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm"
            >
              Select All
            </button>
            <button
              onClick={() => setListPhone([])}
              className="w-full ml-4 lg:w-1/4 mt-5 justify-center rounded-md border border-transparent shadow-lg px-4 py-2 bg-red-600 shadow-red-500/50  text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm"
            >
              Déselect All
            </button>
          </div>

          <div className="mt-3 lg:px-24">
            <label className="text-cyan-400"> Message</label>
            <textarea
              type="text"
              name="name"
              id="name"
              rows={6}
              className="shadow-sm text-gray-700 focus:ring-cyan-500 focus:border-cyan-500 block w-full sm:text-sm border-gray-300 px-4 rounded-lg "
              placeholder=""
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <p
              className={`${
                message.length > 150 ? "text-red-500" : "text-cyan-500"
              }`}
            >
              {" "}
              caractères : {message.length}{" "}
            </p>

            {!statusSend ? (
              <div className=" flex justify-center ">
                <button
                  type="button"
                  className="w-4/5 lg:w-1/2 mt-5 rounded-md border border-transparent shadow-lg px-4 py-2  shadow-cyan-500/50 bg-cyan-600 text-base font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:col-start-2 sm:text-sm"
                  onClick={() => {
                    sendBulk();
                  }}
                  disabled={message.length > 150 || listPhone.length === 0}
                >
                  Envoyé
                </button>
              </div>
            ) : (
              <p className="text-center mt-2"> Message Sent successfully </p>
            )}
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}
