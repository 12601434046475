/* eslint-disable jsx-a11y/no-redundant-roles */
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CheckIcon as CheckIconSolid } from "@heroicons/react/solid";
import ModalForm from "../shared/ModalForm";

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

export default function ListProducts() {
  const [products, setProducts] = useState();

  const getProducts = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/product`,
      // withCredentials: true,
    }).then((res) => {
      setProducts(res.data.products);
      // console.log(res.data.products);
    });
  };

  const handelDelete = async (id) => {
    await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/api/product/${id}`,
      withCredentials: true,
    })
      .then((res) => {
        console.log(res);
        getProducts();
      })
      .catch((err) => {
        // console.log("errrrr ", err.message);
      });
  };

  useEffect(() => {
    getProducts();
  }, []);

  const [open, setOpen] = useState(false);

  return (
    <div className="bg-gray-800 mb-10">
      <ModalForm open={open} setOpen={setOpen} getProducts={getProducts} />
      <div className="">
        <div className="max-w-7xl mx-auto pt-24 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:flex-col sm:align-center">
            <h1 className="text-5xl font-extrabold text-teal-500 sm:text-center">
              Pricing Plans
            </h1>
            <p className="mt-5 text-xl text-gray-100 sm:text-center">
              Start building for free, then add a site plan to go live. Account
              plans unlock additional features.
            </p>
          </div>
          <button
            className="mx-auto mt-2 block md:w-1/2 lg:w-1/2 xl:w-1/4  bg-cyan-600 px-5 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-cyan-700"
            onClick={() => setOpen(true)}
          >
            Add Product{" "}
          </button>
          <div className="p-5 mx-auto mt-5 space-y-4 sm:mt-5 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:mx-auto xl:max-w-none xl:mx-0 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
            {products &&
              products?.map((product) => (
                <div
                  key={product._id}
                  className="border border-gray-200 rounded-lg divide-y divide-gray-200 cursor-pointer shadow-lg"
                  onClick={() => console.log(product._id)}
                >
                  <div className="p-6">
                    {product.monthNumber === 0 ? (
                      <h1 className="text-2xl text-center leading-6 font-medium text-cyan-400">
                        <span className="italic"> 24h Test </span>
                      </h1>
                    ) : (
                      <h1 className="text-2xl text-center leading-6 font-medium text-cyan-400">
                        {product.monthNumber}
                        <span className="italic"> Month(s) </span>
                      </h1>
                    )}
                    <p className="mt-8">
                      {product.price === 0 ? (
                        <>
                          <span className="text-4xl font-extrabold text-cyan-400">
                            ${product.price}
                          </span>
                          <span className="text-base font-medium text-cyan-300">
                            /For 24h
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="text-4xl font-extrabold text-cyan-400">
                            ${product.price}
                          </span>
                          <span className="text-base font-medium text-gray-500">
                            /year
                          </span>
                        </>
                      )}
                    </p>
                    <Link
                      to={`/admin/product/${product._id}`}
                      className="mt-5 block w-full bg-blue-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-blue-700"
                    >
                      Modifier {product.name}
                    </Link>
                    <button
                      onClick={() => {
                        handelDelete(`${product._id}`);
                      }}
                      className="mt-2 block w-full bg-red-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-red-700"
                    >
                      Supprimer {product.name}
                    </button>
                  </div>
                  <div className="pt-6 pb-8 px-6 bg-gray-700">
                    <h3 className="text-xs font-medium text-cyan-400 tracking-wide uppercase">
                      What's included
                    </h3>
                    <ul role="list" className="mt-6 space-y-4">
                      {product.options.map((option, index) => (
                        <li key={index} className="flex space-x-3">
                          <CheckIconSolid
                            className="flex-shrink-0 h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                          <span className="text-sm text-cyan-400">
                            {option}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
