import React, { createContext, useState } from "react";

import axios from "axios";
import "../axios";

// Create Context
const AuthContext = createContext([]);

// Traitement des fonctions pour ensuite les transférer dans le provider
// Est Appeler dans le fichier Index.js
const AuthProvider = (props) => {
  const [isConnect, setIsConnect] = useState(false);
  const [user, setUser] = useState(null);
  const [errorLog, setErrorLog] = useState("");
  const [isSoccer, setIsSoccer] = useState(true);

  // console.log("isSoccer Context", isSoccer);

  const login = (formData) => {
    setErrorLog("");
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/user/login`,
      withCredentials: true,
      data: {
        email: formData.email,
        password: formData.password,
      },
    })
      .then((res) => {
        setUser(res.data.user);

        setIsConnect(true);
        localStorage.setItem("id", JSON.stringify(res.data.user._id));
        localStorage.setItem("name", JSON.stringify(res.data.user.firstName));
        localStorage.setItem("USERR", JSON.stringify(user));
        localStorage.setItem("token", res.data.token);

        setTimeout(() => {
          window.location.replace("/admin");
        }, 2000);
      })
      .catch((err) => {
        console.log("Err", err);
        setErrorLog(`Mot de passe ou Email mal saisie ! `);
      });
  };

  const AuthValue = {
    login,
    isConnect,
    user,
    setUser,
    errorLog,
    setErrorLog,
    setIsSoccer,
    isSoccer,
  };

  // Englobe le provider dans
  return <AuthContext.Provider value={AuthValue} {...props} />;
};

// Export
const Auth = () => React.useContext(AuthContext);
export { Auth, AuthProvider };
