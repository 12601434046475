import { BriefcaseIcon } from "@heroicons/react/solid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import photo from "../../assets/firetv/1.png";

import ReactGA from "react-ga";
const TRACKING_ID = "G-GK0Z1B8NZX";
ReactGA.initialize(TRACKING_ID);

const AboutPage = () => {
  let history = useHistory();
  const [dataMessage, setDataMessage] = useState({
    from: "",
    subject: "",
    message: "",
    phone: "",
  });

  const sendMessage = async (e) => {
    e.preventDefault();
    console.log("send");
    const { from, subject, message, phone } = dataMessage;
    console.log(message);
    console.log(subject);

    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/sendMail`, {
        from,
        subject,
        message,
        phone,
      })
      .then((res) => {
        console.log("message envoyé ");
        setDataMessage({
          from: "",
          subject: "",
          message: "",
          phone: "",
        });
        history.push("/");
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (index, value) => {
    setDataMessage({
      ...dataMessage,
      [index]: value,
    });
    // console.log("dataMessage", dataMessage);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="min-h-full ">
      <div className="mx-auto text-center mt-5 text-4xl uppercase lg:-mb-5">
        un problème ? Ecrivez-nous !
      </div>
      <div className="p-5 text-center mx-auto lg:w-3/4">
        <div className="py-2 px-6 sm:px-10 lg:col-span-2 xl:p-12">
          <form className="mt-0 grid grid-cols-1 gap-y-2 sm:grid-cols-2 sm:gap-x-3 ">
            <div>
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-warm-gray-900"
              >
                Prénom
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  className="py-2 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-cyan-500 focus:border-cyan-500 border-warm-gray-300 rounded-md text-slate-800"
                  onChange={(e) => handleChange("first-name", e.target.value)}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-warm-gray-900"
              >
                Nom
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  autoComplete="family-name"
                  className="py-2 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-cyan-500 focus:border-cyan-500 border-warm-gray-300 rounded-md text-slate-800"
                  onChange={(e) => handleChange("last-name", e.target.value)}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-warm-gray-900"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="py-2 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-cyan-500 focus:border-cyan-500 border-warm-gray-300 rounded-md text-slate-800"
                  onChange={(e) => handleChange("from", e.target.value)}
                />
              </div>
            </div>
            <div>
              <div className="flex justify-between">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-warm-gray-900"
                >
                  Téléphone
                </label>
                {/* <span id="phone-optional" className="text-sm text-warm-gray-500">
                  Optional
                </span> */}
              </div>
              <div className="mt-1">
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  autoComplete="tel"
                  className="py-2 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-cyan-500 focus:border-cyan-500 border-warm-gray-300 rounded-md text-slate-800"
                  aria-describedby="phone-optional"
                  onChange={(e) => handleChange("phone", e.target.value)}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="subject"
                className="block text-sm font-medium text-warm-gray-900"
              >
                Sujet
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  className="py-2 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-cyan-500 focus:border-cyan-500 border-warm-gray-300 rounded-md text-slate-800"
                  onChange={(e) => handleChange("subject", e.target.value)}
                />
              </div>
            </div>
            <div className="sm:col-span-2 mt-5">
              <div className="flex justify-between">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-warm-gray-900"
                >
                  Message :
                </label>
              </div>
              <div className="mt-1">
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  maxLength={500}
                  className="py-2 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-cyan-500 focus:border-cyan-500 border border-warm-gray-300 rounded-md text-slate-800"
                  aria-describedby="message-max"
                  onChange={(e) => handleChange("message", e.target.value)}
                ></textarea>
                <span className="italic text-xs ml-2">
                  {dataMessage.message.length} / 500 characters Max
                </span>
              </div>
            </div>
            <div className="sm:col-span-2 sm:flex sm:justify-end">
              <button
                className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md text-slate-800 shadow-sm text-base font-medium text-white bg-cyan-500 hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:w-auto"
                onClick={sendMessage}
              >
                Envoyé
              </button>
            </div>
          </form>
          <div className="mt-5 text-orange-400 flex flex-row justify-center items-center ">
            <BriefcaseIcon className="h-5 " />
            <p className=" ml-2 underline">
              Become a Resseler ? ...Available Soon
            </p>
          </div>
        </div>
        <img
          src={photo}
          className=" mx-auto rounded-2xl aspect-auto "
          alt="salon"
        />
      </div>
    </div>
  );
};

export default AboutPage;
