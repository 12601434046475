/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Disclosure, Menu } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

import iptv from "../../assets/iptv.png";

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

const navigation = [
  { id: 1, name: "🏡 Accueil", to: "/" },
  { id: 2, name: "📣 Contact", to: "/about" },
  { id: 3, name: "🆘 Aide", to: "/help" },
  { id: 4, name: "🔆 FaQ", to: "/faq" },
  { id: 5, name: "🔐 Login", to: "/login" },
];

export default function Navbar() {
  console.log("localStorage", localStorage.user);
  return (
    <Disclosure as="nav" className="bg-gray-900 z-40">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    className="block lg:hidden h-10 w-auto"
                    src={iptv}
                    alt="Workflow"
                  />
                  <img
                    className="hidden lg:block h-10 w-auto"
                    src={iptv}
                    alt="Workflow"
                  />
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-5">
                    {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                    {navigation.map((nav) => (
                      <div key={nav.id}>
                        <Link
                          to={nav.to}
                          className="text-cyan-300 hover:bg-cyan-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        >
                          {nav.name}
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex items-center">
                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative z-50"></Menu>
                </div>
              </div>
              <div className="-mr-2 flex sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
              {navigation.map((nav) => (
                <div key={nav.id}>
                  <Link to={nav.to}>
                    <Disclosure.Button
                      as="a"
                      className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
                    >
                      {nav.name}
                    </Disclosure.Button>
                  </Link>
                </div>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
