/* eslint-disable jsx-a11y/no-redundant-roles */
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CheckIcon as CheckIconSolid } from "@heroicons/react/solid";
import ModalForm from "../shared/ModalForm";
import ModalUpdateTemplate from "../shared/ModalUpdateTemplate";

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

export default function TemplatePage() {
  const [template, setTemplate] = useState();
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const getTemplate = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/templates`,
      // withCredentials: true,
    }).then((res) => {
      setTemplate(res.data.template);
    });
  };

  const handelDelete = async (id) => {
    await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/api/templates/${id}`,
      withCredentials: true,
    })
      .then((res) => {
        console.log(res);
        getTemplate();
      })
      .catch((err) => {
        // console.log("errrrr ", err.message);
      });
  };

  useEffect(() => {
    getTemplate();
  }, []);

  return (
    <div className="bg-gray-800 mb-10 min-h-screen">
      <ModalForm
        open={open}
        setOpen={setOpen}
        getTemplate={getTemplate}
        setData={setData}
        setLoading={setLoading}
        loading={loading}
      />
      <ModalUpdateTemplate
        open={openUpdate}
        setOpen={setOpenUpdate}
        getTemplate={getTemplate}
        data={data}
        setData={setData}
        setLoading={setLoading}
        loading={loading}
      />
      <div className="">
        <div className="max-w-7xl mx-auto pt-16 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:flex-col sm:align-center">
            <h1 className="text-5xl font-extrabold text-teal-500 sm:text-center">
              Template Page
            </h1>
          </div>
          <button
            className="mx-auto mt-5 block md:w-1/2 lg:w-1/2 xl:w-1/4  bg-orange-600 px-5 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-orange-700"
            onClick={() => setOpen(true)}
          >
            Add Template{" "}
          </button>
          <div className="p-5  mx-auto mt-5 space-y-4 sm:mt-5 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-6 lg:mx-auto xl:max-w-none xl:mx-0 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
            {template &&
              template?.map((item) => (
                <div className="container" key={item._id}>
                  <div className="divide-y divide-gray-200 overflow-hidden rounded-lg shadow border border-gray-200">
                    <div className="px-4 py-5 sm:px-6 text-2xl text-center font-light text-cyan-400">
                      <p className="text-red-500"> Titre</p>
                      {item.title}
                    </div>
                    <p className="px-4 py-5 sm:p-6 font-light text-cyan-400 h-40 break-words   ">
                      {item.body}
                    </p>
                    <div className="px-4 py-2 sm:p-6">
                      <button
                        onClick={() => {
                          setData({
                            title: item.title,
                            body: item.body,
                            id: item._id,
                          });
                          setOpenUpdate(true);
                        }}
                        className="mt-5 block w-full bg-blue-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-blue-700"
                      >
                        Modifier {item.name}
                      </button>
                      <button
                        onClick={() => {
                          handelDelete(`${item._id}`);
                        }}
                        className="mt-2 block w-full bg-red-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-red-700"
                      >
                        Supprimer {item.name}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
