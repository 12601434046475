import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { PANELS } from "../../shared/constants";

const UpdateFormClient = () => {
  let { id } = useParams();
  let history = useHistory();
  // const [startDate, setStartDate] = useState();
  const [dataClient, setDataClient] = useState();
  const [selected, setSelected] = useState(dataClient?.panel);
  const [errors, setErrors] = useState();
  const [formData, setformData] = useState({
    name: "",
    country: "",
    city: "",
    email: "",
    plan: "",
    phone: "",
    startSubscribe: "",
    endSubscribe: "",
    commentaires: "",
    username: "",
    server: "",
    password: "",
  });

  function incr_date(date_str) {
    if (date_str) {
      var parts = date_str.split("-");
      var dt = new Date(
        parseInt(parts[0], 10), // year
        parseInt(parts[1], 10) - 1, // month (starts with 0)
        parseInt(parts[2], 10) // date
      );
      dt.setDate(dt.getDate() + 365);
      parts[0] = "" + dt.getFullYear();
      parts[1] = "" + (dt.getMonth() + 1);
      if (parts[1].length < 2) {
        parts[1] = "0" + parts[1];
      }
      parts[2] = "" + dt.getDate();
      if (parts[2].length < 2) {
        parts[2] = "0" + parts[2];
      }
      return parts.join("-");
    } else return null;
  }

  // console.log(typeof parseInt(formData.startSubscribe), "type of ");
  const dateEnd = incr_date(dataClient?.startSubscribe);
  // console.log(typeof dataClient?.startSubscribe);
  // console.log(dateEnd, "new data");

  const getClient = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/client/${id}`,
      withCredentials: true,
    }).then((res) => {
      setDataClient(res.data);
      // console.log(res.data);
    });
  };

  // console.log("dataClient :>> ", dataClient);

  const handleUpdate = async () => {
    await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/api/client/${id}`,
      withCredentials: true,
      data: {
        name: dataClient.name,
        country: dataClient.country,
        city: dataClient.city,
        email: dataClient.email,
        postCode: dataClient.postCode,
        phone: dataClient.phone,
        plan: dataClient.plan,
        panel: selected,
        startSubscribe: dataClient.startSubscribe,
        endSubscribe: dateEnd,
        commentaires: dataClient.commentaires,
        username: dataClient.username,
        server: dataClient.server,
        password: dataClient.password,
      },
    })
      .then((res) => {
        console.log("donnée envoyé : ", res);
        setErrors("");
        setformData({
          name: "",
          country: "",
          city: "",
          email: "",
          postCode: "",
          phone: "",
          plan: "",
          startSubscribe: "",
          endSubscribe: "",
          panel: "",
          username: "",
          server: "",
          password: "",
        });
        history.push("/admin/addclient");
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handelChange = (index, value) => {
    setDataClient({
      ...dataClient,
      [index]: value,
    });

    // console.log(dataClient);
  };

  useEffect(() => {
    getClient();
  }, []);

  return (
    <div>
      <div className="mt-10 sm:mt-0  pb-5 mb-10">
        <div className="md:grid md:grid-cols-2 md:gap-6">
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-6 gap-2">
                  {/* Name */}
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Nom
                    </label>
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={dataClient?.name}
                      onChange={(e) => handelChange("name", e.target.value)}
                    />
                  </div>
                  {/* Phone Number */}
                  <div className="col-span-6 sm:col-span-3 ">
                    <label
                      htmlFor="phone-number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Phone Number
                    </label>

                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 flex items-center">
                        <label htmlFor="phone" className="sr-only">
                          Phone Number
                        </label>
                        <select
                          id="phone"
                          name="phone"
                          type
                          autoComplete="phone"
                          className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                          value={""}
                          onChange={(e) => console.log(e.target.value)}
                        >
                          <option>CA</option>
                          <option>US</option>
                          <option>EU</option>
                        </select>
                      </div>
                      <input
                        type="number"
                        name="phone-number"
                        id="phone-number"
                        maxLength={10}
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-16 sm:text-sm border-gray-300 rounded-md"
                        placeholder=""
                        value={dataClient?.phone}
                        onChange={(e) => handelChange("phone", e.target.value)}
                      />
                    </div>
                  </div>
                  {/* country */}
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Pays
                    </label>
                    <select
                      id="country"
                      name="country"
                      autoComplete="country-name"
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      value={dataClient?.country}
                      onChange={(e) => {
                        handelChange("country", e.target.value);
                        console.log(e.target.value);
                      }}
                    >
                      <option>choisir</option>
                      <option>🇨🇦 Canada</option>
                      <option>🇺🇸 United States</option>
                      <option>🇫🇷 France</option>
                      <option>🇧🇪 Belgique</option>
                      <option>Afrique</option>
                      <option>Autres</option>
                    </select>
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Ville
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      autoComplete="address-level2"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={dataClient?.city}
                      onChange={(e) => handelChange("city", e.target.value)}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                    <label
                      htmlFor="date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Début Abonnement
                    </label>
                    <input
                      type="date"
                      name="startSubscribe"
                      id="startSubscribe"
                      autoComplete="address-level2"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={dataClient?.startSubscribe}
                      onChange={(e) => {
                        console.log(e.target.value);
                        handelChange("startSubscribe", e.target.value);
                      }}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="plan"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Abonnements
                    </label>
                    <select
                      id="plan"
                      name="plan"
                      autoComplete="plan"
                      value={dataClient?.plan}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      onChange={(e) => {
                        console.log(e.target.value);
                        handelChange("plan", e.target.value);
                      }}
                    >
                      <option>Choisir</option>
                      <option>12 Mois</option>
                      <option>6 Mois </option>
                      <option>3 Mois</option>
                    </select>
                    {dateEnd !== "NaN-NaN-NaN" && (
                      <p className="text-sm italic">
                        Fin abonnement : {dateEnd}
                      </p>
                    )}
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="given-name"
                      placeholder="ibrahima@gmail.com"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={dataClient?.email}
                      onChange={(e) => handelChange("email", e.target.value)}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-light text-gray-600 w-full">
                      Formules
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm w-full">
                      <select
                        className="py-1.5 block w-full text-center focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md text-gray-700"
                        type="select"
                        name="panel"
                        id="panel"
                        defaultValue={dataClient?.panel}
                        onChange={(e) => {
                          setSelected(e.target.value);
                        }}
                      >
                        {PANELS.map((panel) => (
                          <option> {panel.title}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor=""
                      className="block text-sm font-medium text-gray-700"
                    >
                      Username
                    </label>
                    <input
                      type="text"
                      name="username"
                      id="username"
                      autoComplete="given-name"
                      placeholder="RfWE"
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={dataClient?.username}
                      onChange={(e) => handelChange("username", e.target.value)}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <input
                      type="text"
                      name="password"
                      id="password"
                      autoComplete="given-name"
                      placeholder=""
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={dataClient?.password}
                      onChange={(e) => handelChange("password", e.target.value)}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      server
                    </label>
                    <input
                      type="text"
                      name="server"
                      id="server"
                      autoComplete="given-name"
                      placeholder="http://"
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={dataClient?.server}
                      onChange={(e) => handelChange("server", e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-6 sm:col-span-3 lg:col-span-3 p-6">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700"
                >
                  Commentaires :
                </label>
                <textarea
                  rows={4}
                  name="commentaires"
                  id="commentaires"
                  autoComplete="address-level2"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  value={dataClient?.commentaires}
                  onChange={(e) => handelChange("commentaires", e.target.value)}
                />
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => handleUpdate()}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
          <div> {errors && <div> {errors} </div>}</div>
        </div>
        {/* <ListClients clients={dataClients} handelDelete={handelDelete()} /> */}
      </div>
    </div>
  );
};

export default UpdateFormClient;
