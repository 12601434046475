/* eslint-disable jsx-a11y/no-redundant-roles */
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import TableSms from "../components/admin/TableSms";
import { dataMessages } from "../shared/DataMessages";
import Pagination from "../utils/pagination";

export default function SendSmsPage() {
  const [message, setMessage] = useState(dataMessages[1].title);
  const [messageBulk, setMessageBulk] = useState("");
  const [titleSelected, settitleSelected] = useState(null);
  const [phone, setPhone] = useState();
  const [indicatif, setindicatif] = useState("+1");
  const [selectBox, setSelectBox] = useState();
  const [statusSend, setStatusSend] = useState(false);
  const [allMessages, setAllMessages] = useState([]);
  const [selected, setSelected] = useState("-");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [clients, setClients] = useState([]);
  const [listPhone, setListPhone] = useState([]);
  const [listUsers, setListUsers] = useState([]);

  let PageSize = 10;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return allMessages?.slice(firstPageIndex, lastPageIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, allMessages]);

  const getMessages = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/message/sendsms`,
      withCredentials: true,
    })
      .then((res) => {
        // console.log(res.data.messages);
        setAllMessages(res.data.messages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function addCountryCode(numbers) {
    const countryCode = "+1";
    const updatedNumbers = [];

    for (let i = 0; i < numbers.length; i++) {
      if (numbers[i] === null) {
        updatedNumbers.push(null);
      } else {
        const updatedNumber = countryCode + numbers[i];
        updatedNumbers.push(updatedNumber);
      }
    }

    return updatedNumbers;
  }

  const getAllClients = async () => {
    let _phones = [];
    let _users = [{ name: "", phone: "" }];
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/client`,
      withCredentials: true,
    })
      .then((res) => {
        // console.log("res.clients", res.data);
        setClients(res.data.clients);
        console.log("res.data.clients)", res.data.clients);
        if (res.data.clients) {
          res.data.clients.map((item) =>
            _users.push({ phone: item.phone, name: item.name })
          );
        }
        console.log("_users", _users);
        setListUsers(addCountryCode(_phones));
      })
      .catch((err) => console.log("err", err));
  };

  console.log("listPhone", listPhone);

  const sendMessage = async () => {
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/message/sendsms`,
      data: {
        body: message,
        // from: "+12184384469",
        from: process.env.REACT_APP_PHONE,
        to: `${indicatif}${phone}`,
      },
    })
      .then((res) => {
        // history.push("/admin/addclient");
        console.log("selected", selected.toString());
        setStatusSend(true);
        toast.success("success! Message was sent successfully !!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          setStatusSend(false);
          setSelected("-");
          setMessage("");
          // setMessage(dataMessages[3].message);
        }, 1000);
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const sendBulk = async () => {
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/message/bulksms`,
      data: {
        body: { numbers: listPhone, body: messageBulk },
      },
    })
      .then((res) => {
        // history.push("/admin/addclient");

        toast.success("success! Message was sent successfully !!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          setStatusSend(false);
          setSelected("-");
          setMessage("");
          // setMessage(dataMessages[3].message);
        }, 1000);
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getMessages();
    getAllClients();
  }, []);

  const deleteMessage = async (id) => {
    setLoading(true);

    axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/api/message/sendsms/${id}`,
      withCredentials: true,
    })
      .then((res) => {
        getMessages();
        toast.success(`🎉 Message en cours de suppression !!`, {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const formatIndicatif = (e) => {
    console.log(e.target.value);
    let temp = e.target.value;
    setSelectBox(e.target.value);

    if (temp === "EU") {
      setindicatif("+33");
      return indicatif;
    } else if (temp === "US" || temp === "CA") {
      setindicatif("+1");
      return indicatif;
    }
    return indicatif;
  };

  const handleSelect = (event) => {
    setSelected(event.target.value);
    if (event.target.value === "Default") {
      setMessage(dataMessages[0].message);
      settitleSelected(dataMessages[0].title);
    } else if (event.target.value === "Activation") {
      setMessage(dataMessages[1].message);
      settitleSelected(dataMessages[1].title);
    } else if (event.target.value === "Refer") {
      setMessage(dataMessages[2].message);
      settitleSelected(dataMessages[2].title);
    } else if (event.target.value === "New") {
      setMessage(dataMessages[3].message);
      settitleSelected(dataMessages[3].title);
    } else if (event.target.value === "Renew") {
      setMessage(dataMessages[4].message);
      settitleSelected(dataMessages[4].title);
    } else if (event.target.value === "Demande Test") {
      setMessage(dataMessages[5].message);
      settitleSelected(dataMessages[5].title);
    }
  };

  return (
    <div className="bg-gray-100 mb-10">
      <div className="bg-gradient-to-b from-cyan-600 to-cyan-100 p-2">
        <div className="max-w-7xl mx-auto pt-24 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:flex-col sm:align-center">
            <h1 className="text-5xl font-extrabold text-gray-900 text-center">
              Envoi des Messages
            </h1>
            <p className="mt-5 text-xl text-gray-500 sm:text-center">
              Ce système permet d'envoyé a chaque abonné un message de bienvenue
            </p>
          </div>
        </div>
      </div>
      <div className="mx-auto mt-10">
        <div className="lg:mx-52 mx-5">
          <div className="col-span-6 sm:col-span-3 ">
            <label
              htmlFor="phone-number"
              className="block text-sm font-medium text-gray-700"
            >
              Phone Number
            </label>

            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 flex items-center">
                <label htmlFor="phone" className="sr-only">
                  Phone Number
                </label>
                <select
                  id="phone"
                  name="phone"
                  autoComplete="phone"
                  className="focus:ring-cyan-500 focus:border-cyan-500 h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                  value={selectBox}
                  onChange={(e) => formatIndicatif(e)}
                >
                  <option>CA</option>
                  <option>US</option>
                  <option>EU</option>
                </select>
              </div>

              <input
                type="tel"
                name="phone-number"
                id="phone-number"
                className="focus:ring-cyan-500 focus:border-cyan-500 block w-full pl-16 sm:text-sm border-gray-300 rounded-md"
                placeholder="+1 (555) 987-6543"
                maxLength={10}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>
          <div className="col-span-1 mt-2 mb-2 ">
            <select
              id="message"
              name="message"
              type
              value={selected}
              autoComplete="message"
              className="  focus:ring-cyan-500 focus:border-cyan-500 h-8 w-36 py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
              onChange={(event) => handleSelect(event)}
            >
              <option> - </option>
              {dataMessages.map((op, index) => (
                <option key={index}>{op.title}</option>
              ))}
            </select>
          </div>
          <div className="mt-3">
            <label> Message</label>
            <textarea
              type="text"
              name="name"
              id="name"
              rows={6}
              className="shadow-sm focus:ring-cyan-500 focus:border-cyan-500 block w-full sm:text-sm border-gray-300 px-4 rounded-lg"
              placeholder=""
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <p style={{ color: `${message.length > 150 ? "red" : "black"}` }}>
              {" "}
              caractères : {message.length}{" "}
            </p>

            {!statusSend ? (
              <button
                type="button"
                className="w-full mt-5 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:col-start-2 sm:text-sm"
                onClick={() => {
                  sendMessage();
                }}
              >
                Send
              </button>
            ) : (
              <p className="text-center mt-2"> Message Sent successfully </p>
            )}
          </div>
        </div>
      </div>
      <div className="mt-5 text-center">
        <TableSms
          allMessages={currentTableData}
          getMessages={getMessages}
          deleteMessage={deleteMessage}
          loading={loading}
          totalCount={allMessages.length}
          currentPage={currentPage}
          pageSize={PageSize}
        />
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={allMessages.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
      <ToastContainer />
    </div>
  );
}
