/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Dialog, Switch, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Link, useLocation } from "react-router-dom";
import { navigation } from "../../shared/NavigationSidebar";
import axios from "axios";
import iptv from "../../assets/iptv.png";
import { useDispatch, useSelector } from "react-redux";
import { setModeTest, setSaveToken, statusMode } from "../../store/token";
import ButtonPanel from "../admin/ButtonPanel";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Sidebar({ setSidebarOpen, sidebarOpen }) {
  let location = useLocation();
  const dispatch = useDispatch();
  const currentMode = useSelector(statusMode);
  const [enabled, setEnabled] = useState(true);
  const [toggle, setToggle] = useState(enabled);

  useEffect(() => {
    setEnabled(currentMode);
  }, []);

  const logout = () => {
    try {
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/user/logout`,
        withCredentials: true,
      });
      localStorage.removeItem("id");
      localStorage.removeItem("token");
      dispatch(setSaveToken({ tokenRedux: null }));
      window.location.replace("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden w-80   "
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div
              className={`relative flex-1 flex flex-col max-w-xs w-full ${
                currentMode ? "bg-gray-800" : "bg-slate-600"
              } `}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-10 w-auto mx-auto"
                    src={iptv}
                    alt="Workflow"
                  />
                </div>
                <div className="flex justify-center m-5">
                  {!enabled ? (
                    <span className="text-red-500 mr-5"> Mode Démo</span>
                  ) : (
                    <span className="text-teal-500 mr-5"> Mode Prod</span>
                  )}
                  <Switch
                    checked={enabled}
                    onChange={(event) => {
                      setEnabled(!enabled);
                      console.log("event", event);
                      dispatch(setModeTest(event));
                    }}
                    className={classNames(
                      enabled ? "bg-teal-600" : "bg-red-500",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
                    )}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        enabled ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </div>
                <nav className="mt-5 px-2 space-y-1">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.href === location.pathname
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                      )}
                      onClick={() => setSidebarOpen(false)}
                    >
                      <item.icon
                        className={classNames(
                          item.href === location.pathname
                            ? "text-gray-300"
                            : "text-gray-400 group-hover:text-gray-300",
                          "mr-4 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  ))}
                </nav>
              </div>
              <div className="mb-10">
                <div className="flex-shrink-0 flex bg-cyan-500 p-2 mb-2 hover:bg-cyan-700 shadow-lg shadow-cyan-500/50  rounded-r-lg mr-5">
                  <ButtonPanel
                    link={"https://panel.cx"}
                    name={"Panel Magnum"}
                    setSidebarOpen={setSidebarOpen}
                  />
                </div>

                <div className="flex-shrink-0 flex bg-blue-500 p-2 mb-2 hover:bg-blue-700 shadow-lg shadow-blue-500/50 rounded-r-lg mr-5">
                  <ButtonPanel
                    link={"https://4k-panel.net"}
                    name={"Panel 4k"}
                    setSidebarOpen={setSidebarOpen}
                  />
                </div>
                <div className="flex-shrink-0 flex bg-blue-500 p-2 mb-2 hover:bg-blue-700 shadow-lg shadow-blue-500/50 rounded-r-lg mr-5">
                  <ButtonPanel
                    link={"https://panel.theking365tv.info/lines"}
                    name={"Panel King"}
                    setSidebarOpen={setSidebarOpen}
                  />
                </div>
                <div className="flex-shrink-0 flex bg-teal-500 p-2 mb-2 hover:bg-teal-700 shadow-lg shadow-teal-500/50  rounded-r-lg mr-5">
                  <ButtonPanel
                    link={"http://panel.pure-iptv.com/clients"}
                    name={"Panel Pure"}
                    setSidebarOpen={setSidebarOpen}
                  />
                </div>

                <div className="flex-shrink-0 flex bg-orange-600 p-2 hover:bg-orange-700 shadow-lg shadow-orange-500/50  rounded-r-lg mr-5">
                  <button
                    onClick={logout}
                    className="flex-shrink-0 w-full group block"
                  >
                    <div className="flex items-center">
                      <div className="ml-3">
                        <p className="text-md font-medium text-gray-300 group-hover:text-gray-200">
                          Logout
                        </p>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-52 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div
          className={`flex-1 flex flex-col min-h-0 ${
            currentMode ? "bg-gray-900" : "bg-slate-700"
          }`}
        >
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img className="h-12 w-auto mx-auto" src={iptv} alt="Workflow" />
            </div>
            <div className="flex justify-center m-5">
              {!enabled ? (
                <span className="text-red-500 mr-5"> Mode Démo</span>
              ) : (
                <span className="text-teal-500 mr-5"> Mode Prod</span>
              )}
              <Switch
                checked={enabled}
                onChange={(event) => {
                  setEnabled(!enabled);
                  console.log("event", event);
                  dispatch(setModeTest(event));
                }}
                className={classNames(
                  enabled ? "bg-teal-600" : "bg-red-500",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
            <nav className="mt-5 flex-1 px-2 space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.href === location.pathname
                      ? "bg-gray-600 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                  )}
                >
                  <item.icon
                    className={classNames(
                      item.href === location.pathname
                        ? "text-gray-300"
                        : "text-gray-400 group-hover:text-gray-300",
                      "mr-3 flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </Link>
              ))}
            </nav>
          </div>

          <div className="mb-10">
            <div className="flex-shrink-0 flex bg-cyan-500 p-2 mb-2 hover:bg-cyan-700 rounded-r-lg lg:mr-2">
              <ButtonPanel
                link={"https://newpanel.cx"}
                name={"Panel Magnum"}
                setSidebarOpen={setSidebarOpen}
              />
            </div>
            <div className="flex-shrink-0 flex bg-red-500 p-2 mb-2 hover:bg-blue-700 rounded-r-lg lg:mr-2">
              <ButtonPanel
                link={"https://4k-panel.net"}
                name={"Panel 4k"}
                setSidebarOpen={setSidebarOpen}
              />
            </div>
            <div className="flex-shrink-0 flex bg-blue-500 p-2 mb-2 hover:bg-blue-700 rounded-r-lg lg:mr-2">
              <ButtonPanel
                link={"https://panel.theking365tv.info/lines"}
                name={"Panel King"}
                setSidebarOpen={setSidebarOpen}
              />
            </div>
            <div className="flex-shrink-0 flex bg-teal-500 p-2 mb-2 hover:bg-teal-700 rounded-r-lg lg:mr-2">
              <ButtonPanel
                link={"http://panel.pure-iptv.com/clients"}
                name={"Panel Pure"}
                setSidebarOpen={setSidebarOpen}
              />
            </div>

            <div className="flex-shrink-0 flex bg-orange-600 p-2 hover:bg-orange-700 rounded-r-lg lg:mr-2">
              <button
                onClick={logout}
                className="flex-shrink-0 w-full group block"
              >
                <div className="flex items-center">
                  <div className="ml-3">
                    <p className="text-md font-medium text-gray-300 group-hover:text-gray-200">
                      Logout
                    </p>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
