/* eslint-disable jsx-a11y/no-redundant-roles */
import { useState, useEffect } from "react";
import { PencilIcon, ScaleIcon } from "@heroicons/react/outline";
import { UserIcon } from "@heroicons/react/solid";
import axios from "axios";
import { Link } from "react-router-dom";
import { Auth } from "../../context/AuthContext";

const cards = [
  {
    name: "Account balance",
    href: "#",
    icon: ScaleIcon,
    amount: "$30,659.45",
  },
  { name: "Users", href: "/addclient", icon: UserIcon, amount: "120" },
];

const statusStyles = {
  true: "bg-green-100 text-green-800",
  false: "bg-red-100 text-red-600",
  failed: "bg-gray-100 text-gray-800",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CardHeader({ stats }) {
  // const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isSoccer, setIsSoccer] = useState(false);
  // const { isSoccer, setIsSoccer } = Auth();

  let storage = localStorage.getItem("isSoccer");

  const [dataUser, setDataUser] = useState();
  const [status, setStatus] = useState();

  // useEffect(() => {
  //   if (storage === "disponible ") {
  //     setIsSoccer(false);
  //   } else setIsSoccer(true);
  // }, [storage]);

  const getUsers = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/user`,
      withCredentials: true,
    }).then((res) => {
      // console.log(res.data.users);
      setDataUser(res.data.users);
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  // const ACTIV_STATUS = "active";
  // const INACTIVE_STATUS = "inactive";
  // const PENDING_STATUS = "pending";

  const onStatus = async () => {
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/product/status`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { status },
    })
      .then((res) => console.log("res", res))
      .catch((err) => console.log("err", err));
  };

  const getStatus = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/product/status`,
    })
      .then((res) => console.log("res", res.data))
      .catch((err) => console.log("err", err));
  };

  useEffect(() => {
    getStatus();
  }, []);

  return (
    <>
      <div className="min-h-full ">
        <div className="flex flex-col flex-1">
          <main className="flex-1 pb-8">
            {/* Is Match Soccer in Europe Send message Spécifique to front• */}

            <div className="flex justify-center gap-x-4 ">
              <Link
                to="/admin/magnum-client"
                className="bg-red-400 text-white px-3 w-1/5 py-2 text-center rounded-lg "
              >
                Magnum
              </Link>
              <Link
                to="/admin/pure-client"
                className="bg-blue-400 text-white px-3 w-1/5 py-2 text-center rounded-lg "
              >
                Pure
              </Link>
              <Link
                to="/admin/4k-client"
                className="bg-cyan-400 text-white px-3 w-1/5 py-2 text-center rounded-lg "
              >
                4k
              </Link>
              <Link
                to="/admin/king-client"
                className="bg-orange-400 text-white px-3 w-1/5 py-2 text-center rounded-lg "
              >
                King
              </Link>
            </div>

            {/* Page header */}
            <div className="mt-8">
              <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-lg leading-6 font-medium text-gray-100">
                  Détail
                </h2>
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                  {/* Card */}
                  {stats?.map((card) => (
                    <div
                      key={card?.name}
                      className="bg-gray-300 overflow-hidden shadow rounded-lg"
                    >
                      <div className="p-5">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <card.icon
                              className="h-6 w-6 text-teal-500"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              <dt className="text-sm font-medium text-gray-500 truncate">
                                {card?.name}
                              </dt>
                              <dd>
                                <div className="text-lg font-medium text-gray-900">
                                  {card?.amount}
                                </div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-5 py-3">
                        <div className="text-sm">
                          <Link
                            className="font-medium text-cyan-700 hover:text-cyan-900"
                            to={card.url}
                          >
                            View all
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-200 sm:px-6 lg:px-8">
                Liste des Users / Admin
              </h2>

              {/* Activity list (smallest breakpoint only) */}
              <div className="shadow sm:hidden">
                <ul
                  role="list"
                  className="mt-2 divide-y divide-gray-200 overflow-hidden shadow lg:hidden"
                >
                  {dataUser?.map((user) => (
                    <li key={user._id}>
                      <div className="block px-4 py-4 bg-white hover:bg-gray-50">
                        <span className="flex items-center space-x-4">
                          <span className="flex-1 flex space-x-2 truncate">
                            <UserIcon
                              className="flex-shrink-0 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <span className="flex flex-col text-gray-500 text-sm truncate">
                              <span className="truncate">
                                {user.firsName} {user.lastName}
                              </span>
                              <span>
                                <span className="text-gray-900 font-medium">
                                  {user.phone}
                                </span>
                              </span>
                              <span>
                                <span className="text-gray-900 font-medium">
                                  {user.email}
                                </span>
                              </span>
                              <span
                                className={classNames(
                                  statusStyles[user.isAdmin],
                                  "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize"
                                )}
                              >
                                {user.isAdmin ? (
                                  <span> Admin </span>
                                ) : (
                                  <span> Not an Admin </span>
                                )}
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <div className="absolute ml-52 -mt-20">
                        <Link to={"/admin/user/" + user._id}>
                          <PencilIcon
                            className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500 cursor-pointer"
                            aria-hidden="true"
                          />
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Activity table (small breakpoint and up) */}
              <div className="hidden sm:block">
                <div className="max-w-6xl mx-auto px-4 sm:px-2 lg:px-8">
                  <div className="flex flex-col mt-2">
                    <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr>
                            <th className="px-6 py-3 bg-gray-700 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                              Name
                            </th>
                            <th className="px-6 py-3 bg-gray-700 text-center text-xs font-medium text-gray-100 uppercase tracking-wider">
                              Phone
                            </th>

                            <th className="hidden px-6 py-3 bg-gray-700 text-center text-xs font-medium text-gray-100 uppercase tracking-wider md:block">
                              status
                            </th>

                            <th className="px-6 py-3 bg-gray-700 text-center text-xs font-medium text-gray-100 uppercase tracking-wider">
                              Date
                            </th>
                            <th className="px-6 py-3 bg-gray-700 text-center text-xs font-medium text-gray-100 uppercase tracking-wider">
                              Date
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {dataUser?.map((user) => (
                            <tr key={user._id} className="bg-gray-500">
                              <td className="w-full px-6 py-4 whitespace-nowrap text-sm text-cyan-400">
                                <div className="flex">
                                  <Link
                                    to={"/admin/user/" + user._id}
                                    className="group inline-flex space-x-2 truncate text-sm"
                                  >
                                    {/* <UserIcon
                                      className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    /> */}
                                    <p className="text-cyan-400 truncate group-hover:text-cyan-500 ">
                                      {user.firstName} {user.lastName}
                                    </p>
                                  </Link>
                                </div>
                              </td>
                              <td className="px-6 py-4 text-right whitespace-nowrap text-sm ">
                                <span className="text-cyan-400 font-medium">
                                  {user.phone}
                                </span>
                              </td>
                              <td className="px-6 py-4 text-right whitespace-nowrap text-sm ">
                                <span className="text-cyan-400 font-medium">
                                  {user.email}
                                </span>
                              </td>
                              <td className="hidden px-6 py-4 whitespace-nowrap text-sm md:block">
                                <span
                                  className={classNames(
                                    statusStyles[user.isAdmin],
                                    "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize"
                                  )}
                                >
                                  {user.isAdmin ? (
                                    <span> Admin </span>
                                  ) : (
                                    <span> Not an Admin </span>
                                  )}
                                </span>
                              </td>
                              <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-200">
                                <Link to={"/admin/user/" + user._id}>
                                  <PencilIcon
                                    className="flex-shrink-0 h-5 w-5 text-gray-100 group-hover:text-gray-200 cursor-pointer"
                                    aria-hidden="true"
                                  />
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/* Pagination */}
                      {/* <nav
                        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                        aria-label="Pagination"
                      >
                        <div className="hidden sm:block">
                          <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">1</span> to
                            <span className="font-medium">10</span> of
                            <span className="font-medium">20</span> results
                          </p>
                        </div>
                        <div className="flex-1 flex justify-between sm:justify-end">
                          <a
                            href="#"
                            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          >
                            Previous
                          </a>
                          <a
                            href="#"
                            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          >
                            Next
                          </a>
                        </div>
                      </nav> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
