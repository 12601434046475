import React, { useState } from "react";
import moment from "moment";
import "moment/locale/fr";

import ModalSentPanel from "../../components/admin/ModalSentPanel";
import { ToastContainer, toast } from "react-toastify";
import { ChatIcon, ClipboardCopyIcon } from "@heroicons/react/solid";
import CopyToClipboard from "react-copy-to-clipboard";

const TablePanelKing = ({
  clients,
  query,
  sendMessage,
  messagetoSend,
  setMessagetoSend,
  messageError,
  phone,
  setPhone,
  setDefaultMessage,
  defaultMessage,
}) => {
  const format = "DD/MM/YYYY HH:mm";
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [expirateDate, setExpirateDate] = useState();

  const prepareMessage = (user) => {
    setDefaultMessage(`USER:${user.Username || user.username}
MDP:${user.Password || user.password}
URL:*${process.env.REACT_APP_KING_PORTAL}* sans *
AIDE: https://tinyurl.com/winde18`);
  };

  function formatDateInDays(dateString, format) {
    const targetDate = moment(dateString, format);
    const formattedDate = targetDate.fromNow();
    return formattedDate;
  }

  function isDateDifferencePositive(date) {
    const diff = new Date(date) - new Date();
    return diff > 0;
  }

  return (
    <>
      {clients.data
        ?.filter((val) => {
          if (val === "") {
            return val;
          } else if (
            val?.username?.toLowerCase()?.includes(query?.toLowerCase())
          ) {
            return val;
          } else if (
            val?.password?.toLowerCase()?.includes(query?.toLowerCase())
          ) {
            return val;
          }
          return null;
        })
        .map((user) => (
          <tr key={user.id} className="bg-gray-600">
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 flex gap-3">
              <div className="flex">
                <ChatIcon
                  className="h-8 text-teal-300 hover:text-teal-500 cursor-pointer"
                  onClick={() => {
                    // sendMessage(user);
                    setExpirateDate(formatDateInDays(user.exp_date, format));
                    setOpen(true);
                    setCurrentUser(user);
                    prepareMessage(user);
                    setPhone(user.Username || user.username);
                  }}
                />
                <button
                  onClick={() => {
                    toast.success("Copié avec Succès", {
                      position: "top-center",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }}
                >
                  <CopyToClipboard text={defaultMessage}>
                    <ClipboardCopyIcon
                      // onCopy={"hello"}
                      className="h-8 text-teal-300 hover:text-teal-500 cursor-pointer"
                    />
                  </CopyToClipboard>
                </button>
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-cyan-500">
              <p className="text-cyan-500 truncate group-hover:text-cyan-500 ">
                {user.username}
              </p>
            </td>

            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500">
              <span className="text-cyan-500 font-medium">{user.password}</span>
            </td>

            {isDateDifferencePositive(user.exp_date) ? (
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-green-500">
                {formatDateInDays(user.exp_date, format)}
              </td>
            ) : (
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-red-500">
                Expiré {formatDateInDays(user.exp_date, format)}
              </td>
            )}

            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-cyan-500 md:block">
              {user.reseller_notes}
            </td>
          </tr>
        ))}
      <ModalSentPanel
        open={open}
        setOpen={setOpen}
        phone={phone}
        setPhone={setPhone}
        expirateDate={expirateDate}
        messageError={messageError}
        messagetoSend={messagetoSend}
        setMessagetoSend={setMessagetoSend}
        sendMessage={sendMessage}
        defaultMessage={defaultMessage}
        currentUser={currentUser}
      />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default TablePanelKing;
