import React, { useEffect } from "react";
import { notice } from "../../shared/notices";
import tutos from "../../assets/tutos.mp4";

import ReactGA from "react-ga";
const TRACKING_ID = "G-GK0Z1B8NZX";
ReactGA.initialize(TRACKING_ID);

const HelpPage = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="mx-auto text-center mt-2 ">
      <div className="mx-auto text-center mt-5 text-4xl ">Page d'aide </div>

      <div>
        <p className="w-3/4 mx-auto mt-5 text-sm">
          Ceci est une page d'aide afin de vous faciliter l'installation pour
          toutes autres questions n'hésitez pas a nous contacter
          contact@tv-wow.com réponse sous 1h maximum pendant nos horaires
          d'ouverture. <br />
          <span className="text-cyan-400">Tous les jours de 11:00 à 21:00</span>
        </p>
      </div>

      <div className="mt-10 mx-auto overflow-auto p-2">
        <p className="text-red-500 font-bold text-2xl px-1">
          Comment installer ma firestick !
        </p>
        <p className="text-yellow-500 font-bold px-1">
          Si blocage Merci de contacter votre fournisseur !
        </p>

        <div className="flex justify-center mt-5 ">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/7MxPJZmDOeo?si=mXs5zetqMyDCN4jo"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div className="mt-10 mx-auto overflow-auto p-2">
        <p className="text-red-500 font-bold text-2xl px-1">
          Comment ajouter un nouveau Compte
        </p>

        <div className="flex justify-center mt-5 ">
          <video
            width="560"
            height="315"
            controls
            src="https://res.cloudinary.com/dgt1yqlk0/video/upload/v1684878363/Sites/vllo_2_hzmygi.mp4"
          />
        </div>
      </div>

      <div className="mt-10 mx-auto overflow-auto p-2">
        <p className="text-teal-500 font-light mt-5 ">
          Probléme d'application :
        </p>
        <p className="text-red-500 font-bold text-sm px-1">
          Comment Ré-installer L'application si rien ne fonctionne
        </p>

        <div className="flex justify-center mt-5 ">
          <video width="560" height="315" controls>
            <source
              // src="https://res.cloudinary.com/dgt1yqlk0/video/upload/v1674266803/Sites/20230120-203408-612_1_fhrbx1.mp4"
              src={tutos}
              type="video/mp4"
              title="Installer L'application sur votre Firestick"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          </video>
        </div>
      </div>

      <div className="mt-10 mx-auto overflow-auto p-2">
        <h2 className="mt-2 text-xl text-red-400 mx-auto">
          Comment Installer l'application iptv Smarter sur votre firestick a
          suivre au complet
        </h2>

        <div className="flex justify-center mt-5 ">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/Fm4HwvrjQb4"
            title="Installer L'application sur votre Firestick"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        <div className="flex justify-center mt-5 p-2">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/bOl-WSKYzk0"
            title="Comment débloauer option pour les Développeurs"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        <ol>
          {notice.map((instruc) => (
            <div key={instruc.id} className="mx-auto p-1 w-full">
              <li className=" mt-5 ">{instruc.description}</li>
              <img
                className="mx-auto m-1"
                alt={instruc.id}
                src={instruc.id}
                width="600"
                height="700"
              />
            </div>
          ))}
        </ol>
      </div>

      <p className="pt-3 text-cyan-300">
        Bravo si vous êtes arrivés a cette étape, merci de prendre contact avec
        nous !
      </p>
    </div>
  );
};

export default HelpPage;
