import { ChatIcon, PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { KeyIcon, MailIcon } from "@heroicons/react/solid";
import axios from "axios";
import { useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import SmsModal from "../admin/SmsModal";
import DeleteModal from "../DeleteModal";
import Pagination from "../../shared/Pagination";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationSend from "../ConfirmationSend";

let PageSize = 20;

export default function ListClients({ clients, getClients }) {
  let history = useHistory();
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [modalContrat, setModalContrat] = useState(false);
  const [clientContract, setClientContract] = useState();
  const [mail, setMail] = useState("");

  const handelDelete = async (id) => {
    await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/api/client/${id}`,
      withCredentials: true,
    })
      .then((res) => {
        // console.log(res);
        getClients();
        history.push("/admin/addclient");
      })
      .catch((err) => {
        // console.log(err.message);
      });
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return clients?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, clients]);

  const sendContract = async (email) => {
    console.log("send");

    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/client/send-contract`, {
        email,
      })
      .then((res) => {
        // console.log("message envoyé ", res);
        toast.success("🚀 Mail envoyé avec Succès !", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="flex flex-col mt-5 p-1 rounded-2xl">
      <h1 className="text-2xl text-teal-500 text-center mb-5">
        {" "}
        Liste des Clients{" "}
      </h1>
      <div className="-my-2 overflow-x-auto sm:-mx-2 lg:-mx-8">
        <div className="max-w-7xl mx-auto px-2 sm:px-2 lg:px-4">
          <input
            className="border-5 border-emerald-500 w-4/5 lg:w-1/2 mx-auto flex h-10 rounded-lg shadow-md mb-2"
            type="text"
            placeholder="Search By Name..."
            onChange={(e) => setQuery(e.target.value)}
          />
          <div className="flex flex-col mt-2 ">
            <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-500">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                    ></th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                    >
                      Tél.
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                    >
                      Plan
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                    >
                      Ville
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                    >
                      Start Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentTableData === undefined
                    ? null
                    : currentTableData
                        ?.filter((val) => {
                          if (val === "") {
                            return val;
                          } else if (
                            val?.name
                              ?.toLowerCase()
                              ?.includes(query?.toLowerCase())
                          ) {
                            return val;
                          }
                          return null;
                        })
                        .map((client, clientIdx) => (
                          <tr
                            key={client._id}
                            className={
                              clientIdx % 2 === 0 ? "bg-blue-50" : "bg-blue-100"
                            }
                          >
                            <td className="px-2 py-1 text-right text-sm font-medium">
                              <div className="flex gap-2">
                                <Link
                                  to="#"
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  <ChatIcon
                                    className="h-7 w-7"
                                    onClick={(id) => {
                                      setOpen(true);
                                      setDataModal(client);
                                    }}
                                  />
                                </Link>
                                <button
                                  onClick={() => {
                                    setModalContrat(true);
                                    setClientContract(client.email);
                                    // sendContract(client.email);
                                  }}
                                  className="text-red-400 hover:text-red-500 h-6 w-6"
                                >
                                  <KeyIcon />
                                </button>
                                <Link
                                  to={`/admin/client/${client._id}`}
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  <PencilIcon className="text-orange-500 h-6 w-6 hover:text-orange-800 " />
                                </Link>
                                <TrashIcon
                                  onClick={() => {
                                    setOpenDelete(true);
                                    setDataModal(client);
                                  }}
                                  className="text-red-500 ml-3 h-6 w-6 hover:text-red-800 cursor-pointer"
                                />
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {client.name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {client.phone}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {client.panel}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {client.city}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {client.email}
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                              Start: {client.startSubscribe} <br /> Finish :
                              {client.endSubscribe}
                            </td>
                          </tr>
                        ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {currentTableData && query === "" ? (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={clients?.length}
          s
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      ) : null}

      <DeleteModal
        setOpen={setOpenDelete}
        open={openDelete}
        handelDelete={handelDelete}
        data={dataModal}
      />
      <ConfirmationSend
        setOpen={setModalContrat}
        open={modalContrat}
        handelDelete={handelDelete}
        sendContract={sendContract}
        clientContract={clientContract}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <SmsModal open={open} setOpen={setOpen} dataModal={dataModal} />
    </div>
  );
}
