import React from "react";
import { useParams } from "react-router-dom";
import UpdateFormUser from "../components/admin/UpdateFormUser";

const UpdateUser = () => {
  let { id } = useParams();
  console.log(id);

  return (
    <div className="pt-5 m-5">
      <main className="flex-1">
        <h1 className="text-2xl text-center pb-4">
          Mise a jour des utilisateurs
        </h1>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <UpdateFormUser />
        </div>
      </main>
    </div>
  );
};

export default UpdateUser;
