import React from "react";
import bandeau1 from "../../assets/offre/bandeau1.png";

const PubHeader = () => {
  return (
    <div className="mx-auto text-center">
      <p className="text-orange-300 text-sm text-ellipsis">
        {" "}
        ** Offre Limitée **{" "}
      </p>
      <img src={bandeau1} alt="imageHeader" className="mx-auto" />
    </div>
  );
};

export default PubHeader;
