/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import { BanIcon, CreditCardIcon } from "@heroicons/react/solid";
import { CheckIcon as CheckIconSolid } from "@heroicons/react/solid";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import StripeCheckout from "react-stripe-checkout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "moment/locale/fr";
import netflix from "../../assets/netflix.png";
import disney from "../../assets/disney.png";
import canalplus from "../../assets/canalplus.png";
import bein from "../../assets/bein.png";
import skysport from "../../assets/skysport.png";
import foxsports from "../../assets/foxsports.png";
import rds from "../../assets/rds.png";
import dorceltv from "../../assets/dorceltv.png";
import superEcran from "../../assets/superEcran.png";

const DetailProduct = () => {
  let { id } = useParams();
  let history = useHistory();
  const [product, setProduct] = useState();
  const [paimentok, setPaiementok] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(false);
  // const [receipt, setReceipt] = useState("");

  console.log(moment(Date.now()).format("L"));

  const [formData, setformData] = useState({
    name: "",
    email: "",
    phone: "",
    receipt: [],
  });

  const handelChange = (index, value) => {
    setformData({
      ...formData,
      [index]: value,
    });
    console.log(formData);
  };

  const fecthProduct = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/product/${id}`,
      withCredentials: false,
    }).then((res) => {
      setProduct(res.data);
      console.log("Product", res.data);
    });
  };

  const makePayment = async (token) => {
    // console.log("ppppp", product);
    console.log("token", token);

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/payment`,
      {
        token,
        product,
        phone: formData.phone,
        name: formData.name,
      }
    );

    // setReceipt(response.data.receipt_url);

    localStorage.setItem("receipt", response.data.receipt_url);
    let tableReicept = [];
    tableReicept.push(response.data.receipt_url);
    formData.receipt = tableReicept;
    console.log("formData.receipt", formData.receipt);

    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/client`,
      withCredentials: false,
      data: {
        name: formData.name,
        phone: formData.phone,
        receipt: tableReicept,
        email: token.email,
        startSubscribe: moment(Date.now()).format("L"),
        plan: product.monthNumber,
      },
    })
      .then((res) => {
        console.log("donnée envoyé : ", res);
        setformData({
          name: "",
          phone: "",
          receipt: "",
          email: "",
          plan: "",
          startSubscribe: "",
        });
      })
      .catch((err) => {
        console.log(err.message);
      });

    if (response.status === 200) {
      toast.success(" 🎉 success! check you email for more details", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setPaiementok(true);

      console.log("azdazdaz", formData);

      setTimeout(() => {
        history.push("/");
      }, 10000);
    } else {
      toast.error("Faild! an error on you payment", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    console.log(Date.now());
    setMessageSuccess(false);
    fecthProduct();
    localStorage.removeItem("phone");
    setformData({
      name: "",
      phone: "",
      receipt: "",
    });
  }, []);

  // Send Test 24 hours
  const dataSms = {
    body: `🎉 Nouvelle demande TEST 24h: 
    Nom:  ${formData.name}, 
    numéro: ${formData.phone} 
    https://panel.cx 
    https://itpv-ca.herokuapp.com/login `,
    // from: "+12184384469",
    from: process.env.REACT_APP_PHONE,
    to: `+15142226801`,
    name: formData.name,
  };

  const sendMessage = async () => {
    // 1- Send SMS
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/message/sendsms`,
      data: dataSms,
    }).then((res) => {
      console.log("Message SMS", res.data.sms);
      setMessageSuccess(true);
      toast.success(" 🎉 success! You Message was sent successfully", {
        position: "top-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        history.push("/");
      }, 7000);
    });

    // 2- Save user in db
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/client`,
      withCredentials: false,
      data: {
        name: formData.name,
        phone: formData.phone,
        email: formData.email,
      },
    })
      .then((res) => {
        console.log("donnée envoyé : ", res);
        setformData({
          name: "",
          phone: "",
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const paiementButton = product && (
    <div>
      <StripeCheckout
        // stripeKey={process.env.REACT_APP_PKEY}
        // stripeKey={
        //   "pk_live_51JszFJKwJfsMthWbuO3XyZMhDdrggCEZyEEp23LtHvmTkAKpOZ7x9RlB0WiLDEQTEKEcJY4PkWKtGYb00RWNfBY500XdvbRZdR"
        // }
        token={makePayment}
        name={`${product.monthNumber} Month for ${product.price}$cad`}
        amount={product?.price * 100}
        description={`${product.monthNumber} Month for ${product.price}$cad`}
      >
        <button
          className="m-5 h-12 block w-full lg:w-1/2 mx-auto bg-cyan-600 border border-transparent rounded-md py-2 text-sm  text-white text-center hover:bg-cyan-700"
          onClick={() => localStorage.setItem("phone", formData.phone)}
        >
          <CreditCardIcon className="h-8 ml-5" />
          <span className="-mt-8 -ml-6 absolute text-lg ">
            Pay {product?.price} $CAD
          </span>
        </button>
      </StripeCheckout>
    </div>
  );

  const statusMatch = localStorage.getItem("isSoccer");
  console.log("statusMatch", statusMatch);

  return (
    <div className=" flex justify-center mt-10 min-h-screen  ">
      <text className=" text-xl"> Page not Available </text>
    </div>
    // <div className="min-h-screen w-full mx-auto center ">
    //   <div className="text-center mt-5 italic ">
    //     {product?.monthNumber === 0 ? (
    //       <span className="text-2xl text-cyan-300"> Test Gratuit 24h </span>
    //     ) : (
    //       <span className="text-2xl text-cyan-300">
    //         {" "}
    //         {product?.monthNumber} Mois{" "}
    //       </span>
    //     )}
    //   </div>
    //   <div className="text-center mx-auto mt-10">
    //     <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
    //       <span className="md:block"> Tous les produits sont garanties</span>{" "}
    //       <br />
    //       <span className="text-cyan-400 md:block md:-mt-8 lg:mt-4">
    //         N°1 in 🇨🇦 🇺🇸 🇫🇷
    //       </span>
    //     </h1>
    //     {/* <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl ">
    //       Our services allow to watch all channels, series, and Vod Streaming
    //       around the World <br />
    //       🇨🇦 🇫🇷 🇺🇸 🇨🇭 🇮🇹 🇲🇦 🇬🇳 🇸🇳 🇨🇲 🇨🇬 🇨🇮 🇨🇩 🇨🇻 🇹🇷 🇷🇴 ,...
    //     </p> */}
    //     <p className="mt-8 text-lg text-white italic tracking-wide font-semibold sm:mt-10">
    //       Paiement is Secure, For any Question please contact us
    //     </p>
    //   </div>
    //   <div className="mx-auto lg:ml-20 ">
    //     {product && (
    //       <div className="p-5  mx-auto mt-5 space-y-4 sm:mt-5 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:mx-auto  xl:mx-0 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
    //         <div
    //           key={product._id}
    //           className="border border-gray-300 rounded-lg divide-y divide-gray-200 shadow-lg"
    //         >
    //           <div className="p-6">
    //             <p className="mt-2">
    //               <span className="text-4xl font-extrabold text-gray-200">
    //                 ${product.price}
    //               </span>
    //               <span className="text-base font-medium text-gray-500">
    //                 / {product.monthNumber} Month(s)
    //               </span>
    //             </p>
    //           </div>
    //           <div className="pt-6 pb-8 px-6">
    //             <h3 className="text-xs font-medium text-gray-300 tracking-wide uppercase">
    //               {/* What's included */}
    //             </h3>
    //             <ul role="list" className="mt-6 space-y-4">
    //               {product?.options.map((option, index) => (
    //                 <li key={index} className="flex space-x-3">
    //                   <CheckIconSolid
    //                     className="flex-shrink-0 h-5 w-5 text-cyan-500"
    //                     aria-hidden="true"
    //                   />
    //                   <span className="text-sm text-cyan-300">{option}</span>
    //                 </li>
    //               ))}
    //             </ul>
    //           </div>
    //         </div>

    //         {!paimentok && product.price !== 0 ? (
    //           <div className="rounded-lg shadow-xl p-2 ">
    //             <div className="text-center text-xl uppercase tracking-widest ">
    //               Paiement
    //             </div>
    //             <div className=" mt-2 grid lg:grid-cols-2 lg:grid-col-span-2 p-5 lg:space-x-2 ">
    //               <div>
    //                 <label
    //                   htmlFor="phone-number"
    //                   className="block text-sm font-light text-gray-100"
    //                 >
    //                   Phone Number :
    //                 </label>
    //                 <div className="mt-1 relative rounded-md shadow-sm">
    //                   <input
    //                     className="py-3 block w-full text-center focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md text-gray-700"
    //                     type="Tel"
    //                     name="phone"
    //                     id="phone"
    //                     autoComplete="true"
    //                     placeholder=""
    //                     maxLength={10}
    //                     value={formData.phone}
    //                     onChange={(e) => {
    //                       handelChange("phone", e.target.value);
    //                     }}
    //                   />
    //                 </div>
    //               </div>

    //               <div>
    //                 <label
    //                   htmlFor="name"
    //                   className="block text-sm font-light text-gray-100"
    //                 >
    //                   Name :
    //                 </label>
    //                 <div className="mt-1 relative rounded-md shadow-sm">
    //                   <input
    //                     className="py-3 block w-full text-center focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md text-gray-700"
    //                     type="text"
    //                     name="Name"
    //                     id="Name"
    //                     autoComplete="true"
    //                     placeholder="Your Name"
    //                     value={formData.name}
    //                     onChange={(e) => {
    //                       handelChange("name", e.target.value);
    //                     }}
    //                   />
    //                 </div>
    //               </div>
    //             </div>

    //             <div className="text-center">
    //               <ul>
    //                 <li>
    //                   <span className="text-xl"> Price : </span>
    //                   {product.price}
    //                   $CAD
    //                 </li>
    //                 <li>
    //                   <span className="text-md"> Duration : </span>
    //                   {product.monthNumber} Month(s)
    //                 </li>
    //               </ul>
    //               {formData.phone?.length === 10 &&
    //               formData.name.length !== "" ? (
    //                 paiementButton
    //               ) : (
    //                 <div>
    //                   <p className="text-orange-300 -mb-5 mt-2">
    //                     Fill you Phone number before
    //                   </p>
    //                   <button
    //                     className="m-5 h-12 block cursor-not-allowed w-full lg:w-1/2 mx-auto bg-orange-600 border border-transparent rounded-md py-2 text-sm  text-white text-center hover:bg-orange-700"
    //                     disabled
    //                     onClick={() =>
    //                       localStorage.setItem("phone", formData.phone)
    //                     }
    //                   >
    //                     <BanIcon className="h-8 ml-5" />
    //                     <span className="-mt-8 -ml-6 absolute text-lg ">
    //                       Pay {product?.price} $CAD
    //                     </span>
    //                   </button>
    //                 </div>
    //               )}
    //             </div>
    //           </div>
    //         ) : (
    //           <>
    //             {product.price >= 1 ? (
    //               <div className="text-green-300 text-center grid grid-cols-1 space-y-6 lg:space-y-2 mt-10  border-2 border-cyan-300 rounded-lg ">
    //                 <div className="mt-5 tracking-wide font-bold  ">
    //                   🎉 Payment Success, Thank for your trust ... You will
    //                   Receive an Email Soon... Please check
    //                 </div>
    //                 <div>
    //                   <button
    //                     onClick={() => window.open(formData.receipt)}
    //                     className="w-36 h-10 mt-5 mx-auto bg-cyan-300 shadow-cyan-500/50 border border-transparent rounded-md text-sm font-semibold text-black text-center hover:bg-cyan-500 "
    //                   >
    //                     Your Receipt
    //                   </button>
    //                 </div>
    //                 <div className="">
    //                   <Link
    //                     to={"/"}
    //                     className="w-36 h-10  mx-auto bg-blue-300 shadow-blue-500/50 border border-transparent rounded-md text-sm font-semibold text-black text-center hover:bg-blue-500"
    //                   >
    //                     Return Home
    //                   </Link>
    //                 </div>
    //               </div>
    //             ) : (
    //               <div className="mt-12 px-5">
    //                 <>
    //                   <div className=" mt-2 grid lg:grid-cols-1 p-5 lg:space-y-3 ">
    //                     <div>
    //                       <label
    //                         htmlFor="phone-number"
    //                         className="block text-sm font-light text-gray-100"
    //                       >
    //                         Phone Number :
    //                       </label>
    //                       <div className="mt-1 relative rounded-md shadow-sm">
    //                         <input
    //                           className="py-3 block w-full text-center focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md text-gray-700"
    //                           type="Tel"
    //                           name="phone"
    //                           id="phone"
    //                           autoComplete="true"
    //                           placeholder="514-xxx-xxxx"
    //                           maxLength={10}
    //                           value={formData.phone}
    //                           onChange={(e) => {
    //                             handelChange("phone", e.target.value);
    //                           }}
    //                         />
    //                       </div>
    //                     </div>

    //                     <div>
    //                       <label
    //                         htmlFor="name"
    //                         className="block text-sm font-light text-gray-100"
    //                       >
    //                         Name :
    //                       </label>
    //                       <div className="mt-1 relative rounded-md shadow-sm">
    //                         <input
    //                           className="py-3 block w-full text-center focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md text-gray-700"
    //                           type="text"
    //                           name="Name"
    //                           id="Name"
    //                           autoComplete="true"
    //                           placeholder="Your Name"
    //                           value={formData.name}
    //                           onChange={(e) => {
    //                             handelChange("name", e.target.value);
    //                           }}
    //                         />
    //                       </div>
    //                     </div>
    //                     <div>
    //                       <label
    //                         htmlFor="name"
    //                         className="block text-sm font-light text-gray-100"
    //                       >
    //                         Email :
    //                       </label>
    //                       <div className="mt-1 relative rounded-md shadow-sm">
    //                         <input
    //                           className="py-3 block w-full text-center focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md text-gray-700"
    //                           type="email"
    //                           name="email"
    //                           id="email"
    //                           autoComplete="true"
    //                           placeholder="Your email"
    //                           value={formData.email}
    //                           onChange={(e) => {
    //                             handelChange("email", e.target.value);
    //                           }}
    //                         />
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <label
    //                     htmlFor="phone-number"
    //                     className="block text-lg font-light text-gray-100 text-center"
    //                   >
    //                     Receive credentials on my Phone
    //                   </label>
    //                   <div className="mt-3 lg:px-36 self-center relative rounded-md shadow-sm">
    //                     <input
    //                       className="py-3 px-5 block w-full text-center focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md text-gray-700"
    //                       type="Tel"
    //                       name="phone"
    //                       id="phone"
    //                       autoComplete="true"
    //                       placeholder="Your phone Number"
    //                       maxLength={10}
    //                       value={phoneNumber}
    //                       onChange={(e) => {
    //                         setPhoneNumber(e.target.value);
    //                       }}
    //                     />
    //                   </div>

    //                   <div className="mt-3 lg:px-36 self-center relative rounded-md shadow-sm">
    //                     <input
    //                       className="py-3 px-5 block w-full text-center focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md text-gray-700"
    //                       type="text"
    //                       name="name"
    //                       id="name"
    //                       autoComplete="true"
    //                       placeholder="Nom - Prénom"
    //                       minLength={3}
    //                       value={phoneNumber}
    //                       onChange={(e) => {
    //                         setPhoneNumber(e.target.value);
    //                       }}
    //                     />
    //                   </div>
    //                 </>
    //                 ) : (
    //                 <div className="text-center text-justify text-orange-400 shadow-sm ">
    //                   24h TESTis Not Available Yet because <br />
    //                   <strong className="underline">
    //                     " important soccer match in europe is playing Now ".{" "}
    //                     <br />
    //                   </strong>
    //                   Please retry in 1 hour ! Thanks
    //                 </div>
    //                 )}
    //                 {messageSuccess ? (
    //                   <p className=" pt-5 text-center text-green-400">
    //                     Thanks you for you request, you will Receive all
    //                     informations on you phone number !!
    //                   </p>
    //                 ) : (
    //                   <div className="flex mt-5">
    //                     {formData.phone.length >= 10 &&
    //                     formData.name !== "" &&
    //                     formData.email !== "" ? (
    //                       <button
    //                         className="w-52 h-10  mx-auto bg-green-300 shadow-green-500/50 border border-transparent rounded-md text-sm font-semibold text-black text-center hover:bg-green-500"
    //                         onClick={() => sendMessage()}
    //                       >
    //                         Send Message
    //                       </button>
    //                     ) : (
    //                       <p className="text-center mx-auto text-red-300 italic">
    //                         Please enter Phone, Name and email Valid
    //                       </p>
    //                     )}
    //                   </div>
    //                 )}
    //               </div>
    //             )}
    //           </>
    //         )}
    //       </div>
    //     )}
    //     <div className=" mx-auto">
    //       <div className="mt-5 sm:mx-auto sm:max-w-lg lg:ml-0 text-center mx-auto">
    //         <div className="flex flex-wrap items-start justify-between mt-2">
    //           <div className="flex justify-center px-1 mt-2 ">
    //             <img className="h-9 sm:h-10" src={netflix} alt="Tuple" />
    //           </div>
    //           <div className="flex justify-center px-1 mt-2">
    //             <img className="h-9 sm:h-10" src={disney} alt="Workcation" />
    //           </div>
    //           <div className="flex justify-center px-1 mt-2">
    //             <img className="h-9 sm:h-8" src={canalplus} alt="StaticKit" />
    //           </div>
    //           <div className="flex justify-center px-1 mt-2">
    //             <img className="h-9 sm:h-10" src={bein} alt="StaticKit" />
    //           </div>
    //           <div className="flex justify-center px-1 mt-2">
    //             <img className="h-9 sm:h-10" src={skysport} alt="StaticKit" />
    //           </div>
    //           <div className="flex justify-center px-1 mt-2">
    //             <img className="h-9 sm:h-10" src={foxsports} alt="StaticKit" />
    //           </div>
    //           <div className="flex justify-center px-1 mt-2">
    //             <img className="h-9 sm:h-10" src={rds} alt="StaticKit" />
    //           </div>
    //           <div className="flex justify-center px-1 mt-2">
    //             <img className="h-9 sm:h-10" src={dorceltv} alt="StaticKit" />
    //           </div>
    //           <div className="flex justify-center px-1 mt-2">
    //             <img className="h-9 sm:h-10" src={superEcran} alt="StaticKit" />
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <ToastContainer
    //     position="top-right"
    //     autoClose={5000}
    //     hideProgressBar={false}
    //     newestOnTop={false}
    //     closeOnClick
    //     rtl={false}
    //     pauseOnFocusLoss
    //     draggable
    //     pauseOnHover
    //   />
    // </div>
  );
};

export default DetailProduct;
