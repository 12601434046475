/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { CheckIcon as CheckIconSolid } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import ModalAddOptions from "../shared/ModalAddOptions";

const ModifProduct = () => {
  let history = useHistory();
  let { id } = useParams();
  const [product, setProduct] = useState();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    monthNumber: "",
    price: "",
  });

  const [updateOption, setUpdateOption] = useState([]);

  // Get Product Info
  const getProduct = async (_id) => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/product/${id}`,
      withCredentials: false,
    })
      .then((res) => {
        setProduct(res.data);
        // console.log("Res Data : ", res.data);

        setFormData(res.data);

        setUpdateOption(res.data.options);
      })
      .catch((err) => console.log(err));
  };

  // Delete Fonction
  const handelDelete = async (id) => {
    await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/api/product/${id}`,
      withCredentials: true,
    })
      .then((res) => {
        console.log(res);
        // window.location.replace("/products");
        history.push("/products");
        getProduct();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  // Changement du Formulaire
  const handleChange = (index, value) => {
    setFormData({
      ...formData,
      [index]: value,
    });
    // console.log(formData);
  };

  const handleChangeOptions = (index, value) => {
    let temp = updateOption;
    temp[index] = value; // Dans chaque index on modifie par sa valeur
    setUpdateOption(temp);
  };

  // Save
  const handleSave = async (_id) => {
    const t = Object.values(updateOption);
    await axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/api/product/${id}`,
      withCredentials: true,
      data: {
        formData: formData,
        option: t,
      },
    })
      .then((res) => {
        console.log(res);
        getProduct();
        history.push("/admin/products");
      })
      .catch((err) => console.log(err));
  };

  const deleteOption = (p) => {
    // console.log("p", p);

    // let newArray = updateOption.filter((item) => !p.includes(item));
    let newArray = updateOption.filter((item) => p !== item);

    setUpdateOption(newArray);
  };

  // useEffect ici
  useEffect(() => {
    getProduct();
  }, []);

  return (
    <div className="mt-10 p-5 text-center sm:mx-5 ">
      <ModalAddOptions
        open={open}
        setOpen={setOpen}
        updateOption={updateOption}
        setUpdateOption={setUpdateOption}
      />
      <div className="sm:flex sm:flex-col sm:align-center">
        <h1 className="text-5xl font-extrabold text-gray-900 text-center ">
          Detail Product
        </h1>
        <p className="mt-5 text-xl text-gray-500 sm:text-justify ">
          Start building for free, then add a site plan to go live. Account
          plans unlock additional features.
        </p>
      </div>
      <Link
        className="mx-auto mt-2 block md:w-1/2 lg:w-1/2 xl:w-1/4  bg-blue-600 px-5 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-blue-700"
        to={"/admin/products"}
      >
        Retour
      </Link>
      {product && (
        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 ">
          <div className="">
            {/* Card */}
            <div className="p-5 mt-5 space-y-4 sm:mt-5 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-6 lg:max-w-3xl lg:mx-auto xl:max-w-none xl:mx-10 xl:grid-cols-1 ">
              <div className="border border-gray-200 rounded-lg divide-y divide-gray-200  shadow-lg">
                <div className="p-6">
                  <h1 className="text-2xl text-center leading-6 font-medium text-gray-900">
                    {product.monthNumber}{" "}
                    <span className="italic"> Month(s) </span>
                  </h1>
                  <p className="mt-8">
                    <span className="text-4xl font-extrabold text-gray-900">
                      ${product.price}
                    </span>{" "}
                    <span className="text-base font-medium text-gray-500">
                      /year
                    </span>
                  </p>
                  {/* <Link
                    to={`/admin/product/${product._id}`}

                    className="mt-5 block w-full bg-blue-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-blue-700"
                  >
                    Modifier
                  </Link> */}
                  <button
                    onClick={() => {
                      handelDelete(`${product._id}`);
                    }}
                    className="mt-2 block w-1/2 mx-auto bg-red-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-red-700"
                  >
                    Supprimer
                  </button>
                </div>
                <div className="pt-6 pb-8 px-6">
                  <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                    What's included
                  </h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {product.options.map((option, index) => (
                      <li key={index} className="flex space-x-3">
                        <CheckIconSolid
                          className="flex-shrink-0 h-5 w-5 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="text-sm text-gray-500">{option}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Form de modification */}
          <div className="grid grid-cols-1 gap-2 md:mt-5 ">
            <div className="col-span-1">
              <div className="grid md:grid-cols-2 gap-2">
                <div className="col-span-1">
                  <label
                    htmlFor="Month"
                    className="ml-px pl-4 block text-sm font-medium text-gray-700"
                  >
                    Month
                  </label>
                  <div className="mt-1">
                    <input
                      type="number"
                      name="name"
                      id="name"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-lg"
                      placeholder=""
                      value={formData.monthNumber ? formData.monthNumber : ""}
                      onChange={(e) => {
                        handleChange("monthNumber", e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-span-1">
                  <label
                    htmlFor="Month"
                    className="ml-px pl-4 block text-sm font-medium text-gray-700"
                  >
                    Price
                  </label>
                  <div className="mt-1">
                    <input
                      type="number"
                      name="name"
                      id="name"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-lg"
                      placeholder=""
                      value={formData.price ? formData.price : ""}
                      onChange={(e) => {
                        handleChange("price", e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-2 lg:-mt-10 mx-auto">
              <label
                htmlFor="name"
                className="ml-px pl-4 block text-sm font-medium text-gray-700"
              >
                Options :
              </label>
              {updateOption.length > 0 &&
                updateOption.map((p, index) => (
                  <div key={index} className="mt-1  ">
                    <div className="grid grid-cols-3 ">
                      <div className="col-span-2 ">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          defaultValue={p}
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-lg"
                          placeholder=""
                          onChange={(e) => {
                            handleChangeOptions(index, e.target.value);
                          }}
                        />
                      </div>
                      <div className="-ml-10">
                        <button
                          className="w-10 h-8 bg-red-400 hover:bg-red-500 text-white"
                          onClick={() => deleteOption(p)}
                          // onClick={() => console.log(p)}
                        >
                          X
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="grid grid-cols-2 gap-5 mx-auto">
              <button
                onClick={() => {
                  setOpen(!open);
                }}
                className=" w-36 h-10 bg-blue-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white  hover:bg-blue-700 shadow-lg"
              >
                Add Option
              </button>
              <button
                onClick={() => {
                  handleSave(`${product._id}`);
                }}
                className=" w-36  h-10  bg-green-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-green-700 shadow-lg"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModifProduct;
