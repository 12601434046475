// import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Auth } from "../../context/AuthContext";
import { setSaveToken } from "../../store/token";
import photo from "../../assets/firetv/1.png";

import LoaderBig from "../../utils/LoaderBig";
import { Link } from "react-router-dom";

import ReactGA from "react-ga";
const TRACKING_ID = "G-GK0Z1B8NZX";
ReactGA.initialize(TRACKING_ID);

export default function LoginPage(props) {
  const { login, errorLog, setErrorLog } = Auth();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (index, value) => {
    setLoginData({
      ...loginData,
      [index]: value,
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await login(loginData);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error) {
      console.log(errorLog);
    }
  };

  useEffect(() => {
    setErrorLog("");
    dispatch(setSaveToken({ tokenRedux: "" }));
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="min-h-screen m-5">
      <p className="text-center mt-2 italic text-gray-400">
        Seul les administrateurs peuvent se connecter !
      </p>
      <div className="text-center text-3xl mt-10 capitalize ">Login Page</div>

      <form className="text-center mt-10 ">
        <div className="">
          <div className="m-2 p-2">
            <input
              className="rounded-lg shadow-xl w-56 text-cyan-800"
              type="email"
              placeholder="your email "
              value={loginData.email}
              onChange={(e) => handleChange("email", e.target.value)}
            />
          </div>
          <div className="m-2 p-2">
            <input
              className="rounded-lg shadow-xl w-56 text-cyan-800"
              type="password"
              placeholder="Password "
              value={loginData.password}
              onChange={(e) => handleChange("password", e.target.value)}
            />
          </div>
          <div>
            {!loading ? (
              <div>
                <button
                  className="h-10 w-56 bg-cyan-500 hover:bg-cyan-600  rounded-lg drop-shadow-lg"
                  onClick={(e) => submit(e)}
                >
                  Se connecter
                </button>
                <div className="mt-3">
                  <Link
                    className="underline cursor-pointer hover:text-cyan-200 text-md"
                    to={"/"}
                  >
                    Retour à l'accueil
                  </Link>
                </div>
              </div>
            ) : (
              <LoaderBig />
            )}
          </div>
          <p className="text-red-300 mt-5 italic"> {errorLog}</p>

          <img
            src={photo}
            className=" mx-auto rounded-2xl aspect-auto "
            alt="salon"
          />
        </div>
      </form>
    </div>
  );
}
