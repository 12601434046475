import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api`;

const token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers["x-access-token"] = token;
}
axios.defaults.headers["Content-type"] = "application/json";
