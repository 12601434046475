import ReactGA from "react-ga";
ReactGA.initialize("G-GK0Z1B8NZX");

const useAnalyticsEventTracker = (category = "Home") => {
  const eventTracker = (action = "test action", label = "test label") => {
    ReactGA.event({ category, action, label });
  };
  return eventTracker;
};
export default useAnalyticsEventTracker;
