export function addCountryCode(numbers) {
  const countryCode = "+1";
  const updatedNumbers = [];

  for (let i = 0; i < numbers.length; i++) {
    if (numbers[i] === null) {
      updatedNumbers.push(null);
    } else {
      const updatedNumber = countryCode + numbers[i];
      updatedNumbers.push(updatedNumber);
    }
  }

  return updatedNumbers;
}
