import moment from "moment";
import "moment/locale/fr";

export function compareTimestamp(timestamp) {
  const currentDate = moment();
  const entryDate = moment.unix(timestamp);

  if (entryDate.isBefore(currentDate)) {
    return true;
  } else if (entryDate.isAfter(currentDate)) {
    return false;
  } else {
    return;
  }
}
