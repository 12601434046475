import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import ListClients from "./ListClients";
import { PANELS } from "../../shared/constants";

const FormClient = () => {
  // const [startDate, setStartDate] = useState();
  const [dataClients, setDataClients] = useState();
  const [errors, setErrors] = useState();
  const [choosePanel, setChoosePanel] = useState();
  const [choosePlan, setchoosePlan] = useState();
  const [client, setClient] = useState({
    name: "",
    country: "",
    city: "",
    email: "",
    plan: "",
    phone: "",
    startSubscribe: "",
    endSubscribe: "",
    panel: "",
    commentaires: "",
    username: "",
    password: "",
    server: "",
    // postCode: "",
  });

  function incr_date(date_str) {
    var parts = date_str.split("-");
    var dt = new Date(
      parseInt(parts[0], 10), // year
      parseInt(parts[1], 10) - 1, // month (starts with 0)
      parseInt(parts[2], 10) // date
    );
    dt.setDate(dt.getDate() + 365);
    parts[0] = "" + dt.getFullYear();
    parts[1] = "" + (dt.getMonth() + 1);
    if (parts[1].length < 2) {
      parts[1] = "0" + parts[1];
    }
    parts[2] = "" + dt.getDate();
    if (parts[2].length < 2) {
      parts[2] = "0" + parts[2];
    }
    return parts.join("-");
  }

  const dateEnd = incr_date(client.startSubscribe);
  // console.log(dateEnd, "new data");

  const getClients = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/client`,
      withCredentials: true,
    }).then((res) => {
      setDataClients(res.data.clients);
      // console.log("res.data.clients", res.data.clients);
      // console.log(res.data.clients);
    });
  };

  const handleSubmit = async () => {
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/client`,
      withCredentials: false,
      data: {
        name: client.name,
        country: client.country,
        city: client.city,
        email: client.email,
        postCode: client.postCode,
        phone: client.phone,
        plan: choosePlan,
        startSubscribe: client.startSubscribe,
        endSubscribe: dateEnd,
        panel: client.panel,
        commentaires: client.commentaires,
        username: client.username,
        password: client.password,
        server: client.server,
      },
    })
      .then((res) => {
        console.log(res.data);
        // console.log("donnée envoyé : ", res);
        getClients();
        setErrors("");
        setClient({
          name: "",
          country: "",
          city: "",
          email: "",
          postCode: "",
          phone: "",
          plan: "",
          startSubscribe: "",
          endSubscribe: "",
          username: "",
          password: "",
          server: "",
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handelChange = (index, value) => {
    setClient({
      ...client,
      [index]: value,
    });
    // console.log(client);
  };

  const handlePanel = (e) => {
    console.log("e.target.value>>", e.target.value);
    setClient({ ...client, panel: e.target.value });
  };

  // console.log("client :>> ", client);
  const handlePlan = (e) => {
    setchoosePlan(e.target.value);
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <div>
      <div className="mt-10 sm:mt-0 pb-5 mb-10">
        <div className="md:grid md:grid-cols-2 md:gap-2">
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-2 py-5 bg-gray-300 sm:p-2 ">
                <div className="grid grid-cols-6 gap-2">
                  {/* Name */}
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Nom
                    </label>
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={client.name}
                      onChange={(e) => handelChange("name", e.target.value)}
                    />
                  </div>
                  {/* Phone Number */}
                  <div className="col-span-6 sm:col-span-3 ">
                    <label
                      htmlFor="phone-number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Phone Number
                    </label>

                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 flex items-center">
                        <label htmlFor="phone" className="sr-only">
                          Phone Number
                        </label>
                        <select
                          id="phone"
                          name="phone"
                          autoComplete="phone"
                          className="focus:ring-blue-500 focus:border-blue-500 h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                          value={""}
                          onChange={(e) => console.log(e.target.value)}
                        >
                          <option>CA</option>
                          <option>US</option>
                          <option>EU</option>
                        </select>
                      </div>
                      <input
                        type="tel"
                        name="phone-number"
                        id="phone-number"
                        className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-16 sm:text-sm border-gray-300 rounded-md"
                        placeholder="+1 (555) 987-6543"
                        maxLength={10}
                        value={client.phone}
                        onChange={(e) => handelChange("phone", e.target.value)}
                      />
                    </div>
                  </div>
                  {/* country */}
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Pays
                    </label>
                    <select
                      id="country"
                      name="country"
                      autoComplete="country-name"
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      onChange={(e) => {
                        handelChange("country", e.target.value);
                        // console.log(e.target.value);
                      }}
                    >
                      <option>choisir</option>
                      <option>🇨🇦 Canada</option>
                      <option>🇺🇸 United States</option>
                      <option>🇫🇷 France</option>
                      <option>🇧🇪 Belgique</option>
                      <option>Afrique</option>
                      <option>Autres</option>
                    </select>
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Ville
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      autoComplete="address-level2"
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={client.city}
                      onChange={(e) => handelChange("city", e.target.value)}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-1">
                    <label
                      htmlFor="date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Panel
                    </label>
                    <select
                      id="panel"
                      name="panel"
                      autoComplete="panel"
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      onChange={handlePanel}
                      defaultValue={PANELS[1].title}
                    >
                      {PANELS.map((panel, index) => (
                        <option key={index}> {panel.title} </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-3 lg:col-span-1">
                    <label
                      htmlFor="date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Début Abonnement
                    </label>
                    <input
                      type="date"
                      name="startSubscribe"
                      id="startSubscribe"
                      autoComplete="address-level2"
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      // defaultValue={client.startSubscribe}
                      onChange={(e) => {
                        // console.log(e.target.value);
                        handelChange("startSubscribe", e.target.value);
                      }}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-1">
                    <label
                      htmlFor="plan"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Abonnements
                    </label>
                    <select
                      id="plan"
                      name="plan"
                      autoComplete="plan"
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      onChange={handlePlan}
                    >
                      <option>Choisir</option>
                      <option>12 Mois</option>
                      <option>6 Mois </option>
                      <option>3 Mois</option>
                      <option>1 Mois</option>
                    </select>
                    {dateEnd !== "NaN-NaN-NaN" && (
                      <p className="text-sm italic">
                        Fin abonnement : {dateEnd}
                      </p>
                    )}
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="given-name"
                      placeholder="ibrahima@gmail.com"
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={client.email}
                      onChange={(e) => handelChange("email", e.target.value)}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor=""
                      className="block text-sm font-medium text-gray-700"
                    >
                      Username
                    </label>
                    <input
                      type="text"
                      name="username"
                      id="username"
                      autoComplete="given-name"
                      placeholder="RfWE"
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={client.username}
                      onChange={(e) => handelChange("username", e.target.value)}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <input
                      type="text"
                      name="password"
                      id="password"
                      autoComplete="given-name"
                      placeholder=""
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={client.password}
                      onChange={(e) => handelChange("password", e.target.value)}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      server
                    </label>
                    <input
                      type="text"
                      name="server"
                      id="server"
                      autoComplete="given-name"
                      placeholder="http://"
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={client.server}
                      onChange={(e) => handelChange("server", e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-span-6 sm:col-span-3 lg:col-span-3 p-6">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Commentaires :
                  </label>
                  <textarea
                    rows={4}
                    name="commentaires"
                    id="commentaires"
                    autoComplete="address-level2"
                    className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    value={client?.commentaires}
                    onChange={(e) =>
                      handelChange("commentaires", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={() => handleSubmit()}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
          <div> {errors && <div> {errors} </div>}</div>
        </div>
        {/* <ListClients clients={dataClients} handelDelete={handelDelete()} /> */}
        <ListClients
          clients={dataClients}
          dateEnd={dateEnd}
          getClients={getClients}
        />
      </div>
    </div>
  );
};

export default FormClient;
