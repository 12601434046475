import React, { useEffect } from "react";
import Navbar from "../components/layouts/Navbar";
import { Redirect, Route, Switch } from "react-router-dom";
import Home from "../pages/Home";
import Footer from "../components/layouts/Footer";
import LoginPage from "../components/static/LoginPage";
import DetailProduct from "../components/static/DetailProduct";
import RegisterClientPage from "../pages/RegisterClientPage";
import RegisterUserPage from "../pages/RegisterUserPage";
import HelpPage from "../components/static/HelpPage";
import AboutPage from "../components/static/AboutPage";
import ReactGA from "react-ga";
import FaqPage from "../pages/FaqPage";
import AcceptPage from "../pages/AcceptPage";

ReactGA.initialize("G-GK0Z1B8NZX");

const ClientRoute = ({ isSoccer, setIsSoccer }) => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className=" bg-gray-800 text-white ">
      {!localStorage.getItem("id") && <Navbar />}

      <Switch>
        <Route exact component={AboutPage} path={"/about"} />
        <Route exact component={HelpPage} path={"/help"} />
        <Route exact component={FaqPage} path={"/faq"} />
        <Route exact component={DetailProduct} path={"/product/:id"} />
        <Route exact component={RegisterUserPage} path={"/register-user"} />
        <Route exact component={RegisterClientPage} path={"/register"} />
        <Route exact component={LoginPage} path={"/login"} />
        <Route
          exact
          component={AcceptPage}
          path={"/accept-contract-client/:id"}
        />
        <Route
          component={Home}
          path={"/"}
          isSoccer={isSoccer}
          setIsSoccer={setIsSoccer}
        />
        <Redirect to={"/"} />
      </Switch>
      <Footer />
    </div>
  );
};

export default ClientRoute;
