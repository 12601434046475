import Header from "../components/static/Header";
import Presentation from "../components/static/Presentation";
import ProductsSimple from "../components/static/ProductsSimple";
import PubHeader from "../components/static/PubHeader";
import { useEffect, useState } from "react";

import ReactGA from "react-ga";
import useAnalyticsEventTracker from "../utils/google";

ReactGA.initialize("G-GK0Z1B8NZX");

export default function Home() {
  const [isStable, setIsStable] = useState(true);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useAnalyticsEventTracker();

  const scrollButton = () => {
    const titleElement = document.getElementById("view-test");
    titleElement?.scrollIntoView({ behavior: "smooth", inline: "start" });
  };

  return (
    <div className="px-5">
      <div className="mx-auto flex justify-end my-3 ">
        {isStable ? (
          <div className="flex flex-row space-x-3 items-center">
            <div className="w-4 h-4 rounded-full bg-green-300 animate-pulse" />
            <div className="animate-pulse text-sm">Système Stable </div>
          </div>
        ) : (
          <div className="flex flex-row space-x-3 items-center ">
            <div className="w-5 h-5 rounded-full bg-red-400 animate-pulse" />
            <div className="animate-pulse">Système Non-Stable</div>
          </div>
        )}
      </div>
      <PubHeader />
      <Header scrollButton={scrollButton} />
      <Presentation />
      <div id="view-test">
        <ProductsSimple />
      </div>

      {/* <Products /> */}
    </div>
  );
}
