import React, { Fragment, useState } from "react";
import moment from "moment";
import "moment/locale/fr";
import ModalSentPanel from "../../components/admin/ModalSentPanel";

import { ChatIcon, ClipboardCopyIcon } from "@heroicons/react/solid";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import { compareTimestamp } from "../../utils/compareTimeStamps";

const TableMagnum = ({
  clients,
  query,
  sendMessage,
  messagetoSend,
  setMessagetoSend,
  messageError,
  phone,
  setPhone,
  open,
  setOpen,
  setDefaultMessage,
  defaultMessage,
}) => {
  const [currentUser, setCurrentUser] = useState({});
  const [expirateDate, setExpirateDate] = useState();

  const prepareMessage = (user) => {
    setDefaultMessage(`Changement de Serveur 
USER:${user.Username}
MDP:${user.Password}
URL:*${process.env.REACT_APP_MAGNUM_PORTAL}* sans *
AIDE: https://tinyurl.com/winde18`);
  };

  return (
    <>
      {clients.data
        ?.filter((val) => {
          if (val === "") {
            return val;
          } else if (
            val?.Username?.toLowerCase()?.includes(query?.toLowerCase())
          ) {
            return val;
          } else if (
            val?.Password?.toLowerCase()?.includes(query?.toLowerCase())
          ) {
            return val;
          }
          return null;
        })
        .map((user) => (
          <tr key={user.id} className="bg-gray-600">
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 flex gap-3">
              <div className="flex">
                <ChatIcon
                  className="h-8 text-teal-300 hover:text-teal-500 cursor-pointer"
                  onClick={() => {
                    // sendMessage(user);
                    setExpirateDate(
                      moment.unix(user.ExpireDate).format("DD/MM/YYYY")
                    );
                    setOpen(true);
                    setCurrentUser(user);
                    prepareMessage(user);
                    setPhone(user.Username || user.username);
                  }}
                />
                <button
                  onClick={() => {
                    toast.success("Copié avec Succès", {
                      position: "top-center",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }}
                >
                  <CopyToClipboard text={defaultMessage}>
                    <ClipboardCopyIcon
                      // onCopy={"hello"}
                      className="h-8 text-teal-300 hover:text-teal-500 cursor-pointer"
                    />
                  </CopyToClipboard>
                </button>
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              <p className="text-teal-500 truncate group-hover:text-gray-900 ">
                {user.Username}
              </p>
            </td>

            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-cyan-300 ">
              <span className="font-medium">{user.Password}</span>
            </td>
            {/* <td>{format(user.enabled)}</td> */}

            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-cyan-300">
              {compareTimestamp(user.ExpireDate) ? (
                <span className="text-red-400">
                  {" "}
                  expiré le {moment.unix(user.ExpireDate).format("DD/MM/YYYY")}
                </span>
              ) : (
                <p>
                  <span> {moment.unix(user.ExpireDate).fromNow()} </span>
                  le {moment.unix(user.ExpireDate).format("DD/MM/YYYY")}
                </p>
              )}
            </td>
            <td className=" px-6 py-4 text-center whitespace-nowrap text-sm text-cyan-300">
              {user.reseller_notes}
            </td>
          </tr>
        ))}
      <ModalSentPanel
        open={open}
        setOpen={setOpen}
        phone={phone}
        setPhone={setPhone}
        expirateDate={expirateDate}
        messageError={messageError}
        messagetoSend={messagetoSend}
        setMessagetoSend={setMessagetoSend}
        sendMessage={sendMessage}
        defaultMessage={defaultMessage}
        currentUser={currentUser}
      />
    </>
  );
};

export default TableMagnum;
