import React from "react";
import UpdateFormClient from "../components/admin/UpdateFormClient";
import { useParams } from "react-router-dom";

const UpdateClient = () => {
  let { id } = useParams();
  console.log(id);

  return (
    <div className="pt-5 m-5">
      <main className="flex-1">
        <h1 className="text-2xl text-center pb-4"> Mise à jour Client </h1>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <UpdateFormClient />
        </div>
      </main>
    </div>
  );
};

export default UpdateClient;
