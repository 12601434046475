/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { Fragment, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { CheckIcon as CheckIconSolid } from "@heroicons/react/solid";
import axios from "axios";
import { LockClosedIcon, MailIcon } from "@heroicons/react/outline";
import useAnalyticsEventTracker from "../../utils/google";

import { PANELS } from "../../shared/constants";
import { toast } from "react-toastify";
import { dataMessages } from "../../shared/DataMessages";

export default function ProductsSimple() {
  const [products, setProducts] = useState();
  const gaEventTracker = useAnalyticsEventTracker("Achat");
  const [activForm, setActivForm] = useState(false);
  const [selected, setSelected] = useState(PANELS[1].title);

  const [message, setMessage] = useState(dataMessages[5].message);
  const [messageError, setMessageError] = useState("");

  const indicatif = "+1";

  const [statusSend, setStatusSend] = useState(false);

  const [formData, setformData] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    receipt: [],
    panel: "",
  });

  const getProducts = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/product`,
    }).then((res) => {
      setProducts(res.data.products);
      console.log(res.data.products);
    });
  };

  const sendMail = async () => {
    await axios({
      method: "POST",
      withCredentials: false,
      url: `${process.env.REACT_APP_API_URL}/api/sendtest`,
      data: {
        to: formData.email,
        name: formData.name,
        phone: formData.phone,
        message: "",
      },
    })
      .then((res) => {
        console.log("res", res);
      })
      .catch((err) => console.log("__** ERROR**__ =>", err));
  };

  const handelChange = (index, value) => {
    setformData({
      ...formData,
      [index]: value,
    });
  };

  const saveClient = async () => {
    formData.panel = selected;
    console.log("formData :>> ", formData);
    setMessageError("");
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/client`,
      data: formData,
    })
      .then((res) => {
        console.log("RESPONSE", res.data);
        if (res.data.status === "success") {
          sendMail();
          toast.success(" 🚀 Bravo, Message Envoyé !!", {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // sendMessageToClient();
          sendMessageToMe();
          setActivForm(false);
          setformData({
            name: "",
            email: "",
            phone: "",
            city: "",
            panel: "",
            receipt: [],
            // panel: selected.title,
          });
        } else {
          if (
            res.data.message.code === 11000 &&
            res.data.message.keyValue.email !== ""
          ) {
            setMessageError(
              <div className="border-2 border-red-400 rounded-lg p-2">
                ❌ {res.data.message.keyValue.email} existe déja Merci de nous
                écrire{" "}
                <Link
                  to={"/about"}
                  className="underline underline-offset-2 text-teal-400 uppercase"
                >
                  ce formulaire de contact{" "}
                </Link>
              </div>
            );
            toast.error(`❌ ${res.data.message.keyValue.email} existe déja`, {
              position: "top-center",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setTimeout(() => {
              setMessageError("");
            }, 10000);
          }
        }
      })
      .catch((err) => console.log("err", err));
  };

  const activbutton =
    formData.email !== "" &&
    formData.phone !== "" &&
    formData.name !== "" &&
    formData.city !== ""
      ? true
      : false;

  // Not available Yet
  // const sendMessageToClient = async () => {
  //   await axios({
  //     method: "POST",
  //     url: `${process.env.REACT_APP_API_URL}/api/message/sendsms`,
  //     data: {
  //       body: dataMessages[5].message,
  //       from: "+12184384469",
  //       to: `${indicatif}${formData.phone}`,
  //       title: "Demande Test",
  //     },
  //   })
  //     .then((res) => {
  //       // history.push("/admin/addclient");
  //       setStatusSend(true);
  //       toast.success(" 🚀 Bravo, Message Envoyé !!", {
  //         position: "top-center",
  //         autoClose: 2500,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //       setTimeout(() => {
  //         setStatusSend(false);
  //         setSelected("-");
  //         setMessage("");
  //         // setMessage(dataMessages[3].message);
  //       }, 1000);
  //       console.log(res);
  //     })
  //     .catch((err) => console.log(err));
  // };

  const sendMessageToMe = async () => {
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/message/sendsms`,
      data: {
        body: `Nouvelle Demande de test pour ${formData.name} - ${
          formData.phone
        } - ${formData.email} 
        link : ${"https://panel.cx"}`,
        // from: "+12184384469",
        from: process.env.REACT_APP_PHONE,
        to: `5142226801`,
        title: "Message",
      },
    })
      .then((res) => {
        // history.push("/admin/addclient");
        setStatusSend(true);

        setTimeout(() => {
          setStatusSend(false);
          setSelected("-");
          setMessage("");
        }, 1500);
        // console.log(res);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getProducts();
  }, []);

  const viewForm = () => {
    console.log("View");
    setActivForm(true);

    setTimeout(() => {
      document.getElementById("formulaire").scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 1000);
  };

  return (
    <div className="sm:px-5 md:px-5">
      <div className="p-5 mx-auto mt-5 space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:mx-auto xl:max-w-none xl:mx-0 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
        {(
          <p className="text-center p-10 text-white">
            ... Loading, please Wait or reload the page !
          </p>
        ) &&
          products?.map((product) => (
            <div
              key={product._id}
              className="border border-gray-300 rounded-lg divide-y divide-gray-200 shadow-lg"
            >
              <div className="p-4">
                {product.price === 0 ? (
                  <p className="mt-2">
                    <span className="text-3xl text-center mx-auto ml-5 font-extrabold text-gray-200">
                      {/* Free - ${product.price} */}
                      Free
                    </span>
                    {/* <span className="text-base font-medium text-gray-500">
                      / 24 hrs
                    </span> */}
                  </p>
                ) : (
                  <p className="mt-2">
                    <span className="text-4xl font-extrabold text-gray-200">
                      ${product.price}
                    </span>
                    <span className="text-base font-medium text-gray-500">
                      / {product.monthNumber} Month(s)
                    </span>
                  </p>
                )}
                {product.price === 0 ? (
                  <button
                    className="h-16 mt-5 block w-full bg-green-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-cyan-700"
                    onClick={() => viewForm()}
                  >
                    <div className="text-center flex justify-center ">
                      {/* <MailIcon className=" h-5 mr-2" /> */}
                      <span className=""> S'inscrire </span>
                    </div>
                  </button>
                ) : (
                  <Link
                    className="mt-5 block w-full bg-cyan-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-cyan-700"
                    onClick={() => gaEventTracker(`Test-${product._id}`)}
                    to={`/product/${product._id}`}
                  >
                    <div className="text-center flex justify-center ">
                      <LockClosedIcon className=" h-5 mr-2" />
                      <span className="">Pay $</span>
                    </div>
                  </Link>
                )}

                {/* <button
                className="mt-2 block w-full bg-green-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-green-700  "
                onClick={() => history.push(`/paiement/${product._id}`)}
              >
                $ {product.price}
              </button> */}
              </div>
              <div className="pt-6 pb-8 px-6">
                <h3 className="text-xs font-medium text-gray-300 tracking-wide uppercase">
                  Inclus
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {product?.options.map((option, index) => (
                    <li key={index} className="flex space-x-3">
                      <CheckIconSolid
                        className="flex-shrink-0 h-5 w-5 text-cyan-500"
                        aria-hidden="true"
                      />
                      <span className="text-sm text-cyan-300">{option}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}

        {activForm && (
          <div
            className=" -mx-5 flex align-middle flex-col p-1"
            id="formulaire"
          >
            <div className="text-2xl text-center"> Demande de Test 24h</div>
            <div>
              <div className=" mt-2 grid lg:grid-cols-2 lg:grid-col-span-2 p-1">
                {/* Name  test */}
                <div className="w-full mb-2 p-1">
                  <label className="block text-sm font-light text-gray-100 w-full">
                    Prénom & Nom de famille
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm w-full">
                    <input
                      className="py-3 block w-full text-center focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md text-gray-700"
                      type="text"
                      name="Name"
                      id="Name"
                      autoComplete="true"
                      placeholder="Prénon et Nom de Famille"
                      value={formData.name}
                      onChange={(e) => {
                        handelChange("name", e.target.value);
                      }}
                    />
                  </div>
                </div>

                {/* Phone Number  */}
                <div className="w-full mb-2 p-1">
                  <label className="block text-sm font-light text-gray-100">
                    Téléphone :{" "}
                    <span className="text-red-300">
                      {" "}
                      sans tiret et sans espace
                    </span>
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm w-full">
                    <input
                      className="py-3 block w-full text-center focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md text-gray-700"
                      type="Tel"
                      name="phone"
                      id="phone"
                      autoComplete="true"
                      placeholder="514xxxxxx"
                      maxLength={10}
                      value={formData.phone}
                      onChange={(e) => {
                        handelChange("phone", e.target.value);
                      }}
                    />
                  </div>
                </div>

                {/* Email */}
                <div className="w-full mb-2 p-1">
                  <label className="block text-sm font-light text-gray-100 w-full">
                    E-mail :
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm w-full">
                    <input
                      className="py-3 block w-full text-center focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md text-gray-700"
                      type="text"
                      name="email"
                      id="email"
                      autoComplete="true"
                      placeholder="Ton email"
                      value={formData.email}
                      onChange={(e) => {
                        handelChange("email", e.target.value);
                      }}
                    />
                  </div>
                </div>

                {/* Ville */}
                <div className="w-full mb-2 p-1">
                  <label className="block text-sm font-light text-gray-100 w-full">
                    Ville :
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm w-full">
                    <input
                      className="py-3 block w-full text-center focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md text-gray-700"
                      type="text"
                      name="city"
                      id="city"
                      autoComplete="true"
                      placeholder="Ta Ville"
                      value={formData.city}
                      onChange={(e) => {
                        handelChange("city", e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* Panel choisit */}
              <div className="w-full mb-2 p-1">
                <label className="block text-sm font-light text-gray-100 w-full">
                  Formules : * Le Panel STANDARD est toujours disponible mais
                  n'est plus maintenues
                </label>
                <div className="mt-1 relative rounded-md shadow-sm w-full">
                  <select
                    className="py-3 block w-full text-center focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md text-gray-700"
                    type="select"
                    name="panel"
                    id="panel"
                    defaultValue={PANELS[1].title}
                    onChange={(e) => {
                      setSelected(e.target.value);
                    }}
                  >
                    {PANELS.map((panel) => (
                      <option> {panel.title}</option>
                    ))}
                  </select>
                </div>
              </div>

              {messageError ? (
                <p className="text-center  text-red-400 font-mono">
                  {" "}
                  {messageError}
                </p>
              ) : null}
              {activbutton ? (
                <button
                  className="mx-auto mt-5 block px-5 hover:bg-green-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center bg-cyan-700"
                  onClick={saveClient}
                >
                  <div className="text-center flex justify-center ">
                    <MailIcon className=" h-5 mr-2" />
                    <span className=""> Envoyé ma demande</span>
                  </div>
                </button>
              ) : (
                <button
                  disabled
                  className=" cursor-not-allowed mx-auto mt-5 block px-5 hover:bg-gray-700 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center bg-gray-600"
                  onClick={saveClient}
                >
                  <div className="text-center flex justify-center ">
                    <MailIcon className=" h-5 mr-2" />
                    <span className=""> Remplir tous les champs</span>
                  </div>
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
