import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Sidebar from "../components/layouts/Sidebar";
import { MenuIcon } from "@heroicons/react/outline";
import Dashboard from "../pages/Dashboard";
import AddClient from "../pages/AddClient";
import ListProducts from "../pages/ListProducts";
import ModifProduct from "../pages/ModifProduct";
import SendSmsPage from "../pages/SendSmsPage";
import UpdateClient from "../pages/UpdateClient";
import PrivateRoute from "../PrivateRoute";
import UpdateUser from "../pages/UpdateUser";
import BulkSms from "../pages/BulkSms";
import PureClients from "../pages/pure/PureClients";
import KingClients from "../pages/king/KingClients";
import MagnumClients from "../pages/magnum/MagnumClients";
import FourKClients from "../pages/fourK/FourKClients";
import TemplatePage from "../pages/TemplatePage";

export default function AdminRoute() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="bg-gray-800">
      {localStorage.getItem("id") && (
        <>
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        </>
      )}

      <div className="md:pl-52  flex flex-col flex-1">
        <div className="sticky top-0 z-10 md:hidden  pt-1 sm:pl-3 sm:pt-3 bg-gray-800">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        {localStorage.getItem("id") ? (
          <Switch>
            <PrivateRoute component={UpdateClient} path={"/admin/client/:id"} />
            <PrivateRoute component={UpdateUser} path={"/admin/user/:id"} />
            <PrivateRoute component={AddClient} path={"/admin/addclient"} />
            <PrivateRoute component={ListProducts} path={"/admin/products"} />
            <PrivateRoute component={TemplatePage} path={"/admin/template"} />
            <PrivateRoute
              component={ModifProduct}
              path={"/admin/product/:id"}
            />
            <PrivateRoute component={SendSmsPage} path={"/admin/sms"} />
            <PrivateRoute component={BulkSms} path={"/admin/bulksms"} />
            <PrivateRoute component={PureClients} path={"/admin/pure-client"} />
            <PrivateRoute component={KingClients} path={"/admin/king-client"} />
            <PrivateRoute component={FourKClients} path={"/admin/4k-client"} />
            <PrivateRoute
              component={MagnumClients}
              path={"/admin/magnum-client"}
            />
            <PrivateRoute component={Dashboard} exact path={"/admin"} />
            <Route exact path="/">
              {localStorage.getItem("id") && <Redirect to="/admin" />}
            </Route>
          </Switch>
        ) : (
          <Switch>
            {/* <Route exact component={LoginPage} path={"/login"} /> */}
            {/* <Route exact component={Home} path={"/"} /> */}
          </Switch>
        )}
        <p className="mt-12 text-xs text-center mb-10 text-cyan-400 md:mt-0 md:order-1 ">
          🚀 Made with ❤️
        </p>
      </div>
    </div>
  );
}
