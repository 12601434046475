import axios from "axios";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

const RegisterClientPage = () => {
  let history = useHistory();
  const [dataForm, setDataForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    city: "",
  });

  const handlechange = (index, value) => {
    setDataForm({
      ...dataForm,
      [index]: value,
    });
  };

  const registerUser = async (e) => {
    await axios({
      method: "POST",
      withCredentials: false,
      url: `${process.env.REACT_APP_API_URL}/api/user/register`,
      data: dataForm,
    })
      .then((res) => {
        console.log("success Register", res.data);
        setDataForm({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          phone: "",
          city: "",
        });
        setTimeout(() => {
          history.push("/login");
        }, 3000);
      })
      .catch((err) => console.log("error", err));
  };

  return (
    <div className=" min-h-screen mt-36 ">
      <h1 className="text-2xl text-center">🤷‍♂️ User Register Page</h1>

      <form className="lg:ml-36 lg:mr-36 ">
        <div className="grid lg:grid-cols-2 lg:gap-5 md:grid-cols-2 md:gap-5 m-10 text-stone-700">
          <div className=" mt-5 lg:mt-0">
            <label htmlFor="name" className="text-white">
              First name
            </label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              autoComplete="firstName"
              placeholder="Full name"
              required
              // value={dataForm.name}
              className="w-full shadow-sm focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm border-gray-300 rounded-md "
              onChange={(e) => {
                handlechange("firstName", e.target.value);
                // console.log(e.target.value);
              }}
            />
          </div>
          <div className=" mt-5 lg:mt-0">
            <label htmlFor="name" className="text-white">
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              autoComplete="lastName"
              placeholder="Full name"
              required
              // value={dataForm.name}
              className="w-full shadow-sm focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm border-gray-300 rounded-md "
              onChange={(e) => {
                handlechange("lastName", e.target.value);
                // console.log(e.target.value);
              }}
            />
          </div>

          <div className="mt-5 lg:mt-0 ">
            <label htmlFor="mobile-or-email" className="text-white">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              autoComplete="email"
              placeholder="Mobile number or email"
              required
              className="block w-full shadow-sm focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm border-gray-300 rounded-md"
              onChange={(e) => {
                handlechange("email", e.target.value);
                // console.log(e.target.value);
              }}
            />
          </div>

          <div className="mt-5 lg:mt-0 ">
            <label htmlFor="phone" className="text-white">
              You Phone
            </label>
            <input
              type="tel"
              name="phone"
              id="phone"
              autoComplete="phone"
              placeholder="Mobile number or phone"
              required
              className="block w-full shadow-sm focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm border-gray-300 rounded-md"
              onChange={(e) => {
                handlechange("phone", e.target.value);
                // console.log(e.target.value);
              }}
            />
          </div>

          <div className="mt-5 lg:mt-0">
            <label htmlFor="city" className="text-white ">
              city
            </label>
            <input
              id="city"
              name="city"
              type="text"
              placeholder="city"
              autoComplete="city"
              required
              className="block w-full shadow-sm focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm border-gray-300 rounded-md text-black"
              onChange={(e) => {
                handlechange("city", e.target.value);
                console.log(e.target.value);
              }}
            />
          </div>

          <div className="mt-5 lg:mt-0">
            <label htmlFor="password" className="text-white ">
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              autoComplete="current-password"
              required
              className="block w-full shadow-sm focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm border-gray-300 rounded-md text-black"
              onChange={(e) => {
                handlechange("password", e.target.value);
                console.log(e.target.value);
              }}
            />
          </div>

          <div className="mt-5 lg:mt-0">
            <label htmlFor="repeatPassword" className="text-white ">
              Repeat Password
            </label>
            <input
              id="repeatPassword"
              name="repeatPassword"
              type="password"
              placeholder="repeatPassword"
              autoComplete="repeatPassword"
              required
              className="block w-full shadow-sm focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm border-gray-300 rounded-md text-black"
              onChange={(e) => {
                handlechange("repeatPassword", e.target.value);
                // console.log(e.target.value);
              }}
            />
          </div>
        </div>
      </form>
      {dataForm.password === dataForm.repeatPassword &&
      dataForm.password !== "" &&
      dataForm.repeatPassword !== "" ? (
        <div className="ml-10 mr-10 lg:ml-36 lg:mr-36 flex flex-col items-center">
          <button
            type="submit"
            className="w-full lg:w-56 md:w-56 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
            onClick={() => registerUser()}
          >
            Create My account
          </button>
          <p className="text-sm mt-2 italic text-gray-400">
            already an account ?
            <Link className=" underline cursor-pointer" to={"/login"}>
              <br /> <span> Go to Login Page ! </span>
            </Link>
          </p>
        </div>
      ) : (
        <div className="ml-10 mr-10 lg:ml-36 lg:mr-36 flex flex-col items-center">
          <button
            type="submit"
            disabled
            className="w-full cursor-not-allowed lg:w-56 md:w-56 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-300 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
            onClick={() => registerUser()}
          >
            Create My account
          </button>
          <p className="text-sm mt-2 italic text-gray-400">
            already an account ?
            <Link className=" underline cursor-pointer" to={"/login"}>
              <br /> <span> Go to Login Page ! </span>
            </Link>
          </p>
        </div>
      )}
    </div>
  );
};

export default RegisterClientPage;
