import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

const faqs = [
  {
    question: "Comment avoir une Firestick ? ",
    answer:
      "Nous vendons les firesticks et merci de nous en faire la demande sinon tu peux les acheter au point le plus proche de chez toi ouy Amazon",
  },
  {
    question: "Je ne vois pas Option pour les développeurs que faire ? ",
    answer: `Rends toi dans le menu principale puis va dans les settings (réglages) => ma fireTv => About => click sur le bouton central plusieurs fois 10x et un menu en bas va s'ouvrir pour te dire que tu es devenu developpeur alors retourne en arrière puis va dans << Option pour les developpeur >> `,
  },
  // More questions...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FaqPage() {
  return (
    <div className=" min-h-screen  ">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-bold tracking-tight text-cyan-300 sm:text-4xl">
            Les Questions les plus fréquemments posées !
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-cyan-400">
                        <span className="font-medium text-red-400">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-cyan-400">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
