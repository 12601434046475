import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tokenRedux: null,
  modeTest: true,
};

export const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setSaveToken: (state, action) => {
      const { payload } = action;
      state.tokenRedux = payload;
      return state;
    },
    setModeTest: (state, action) => {
      const { payload } = action;
      state.modeTest = payload;
      return state;
    },
  },
});

export default tokenSlice.reducer;
// Action creators are generated for each case reducer function
export const { tokenReducer } = (state) => state.token.tokenRedux;
export const statusMode = (state) => state.token.modeTest;
export const { setSaveToken, setModeTest } = tokenSlice.actions;
